import Account from '@/utils/api/odas/account';
import mapValues from 'lodash/mapValues';

const passwordValidationString = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[/\W/g_])[A-Za-z\d/\W/g_]{8,}$/;

let samlConfigPromise = null;

const accountHelpers = {
  checkPassword: (value) => {
    return value.match(passwordValidationString);
  },
  fetchSamlConfig: () => {
    if (samlConfigPromise === null) {
      samlConfigPromise = Account.getSAMLConfiguration().then((response) => {
        return mapValues(response?.data?.saml_config, (partner, slug) => {
          return Object.assign({ slug }, partner, { email_regex: new RegExp(partner.email_regex.trim().replace(/^\//, '').replace(/\/$/, ''), 'i') });
        });
      });
    }
    return samlConfigPromise;
  },
  isPasswordMatched: (password, password_confirmation) => {
    return password === password_confirmation;
  },
  enqueueSignupModalFlow: ({ enqueueModal, modalData, language, onFlowComplete = () => {}, ...options } = {}) => {
    if (modalData?.affiliation_list) {
      enqueueModal({
        name: 'SignupAffiliationDetails',
        language: language,
        affiliationData: modalData
      });
    } else if (modalData?.user?.is_professional) {
      enqueueModal({
        name: 'SignupProDetails'
      });
    }
    enqueueModal({
      name: 'SignupMemberDetails',
      language: language,
      afterSubmit: onFlowComplete
    });
  }
};

export default accountHelpers;
