import moment from 'moment';

export const DateHelper = {
  daysFiller: function () {
    var days = [];
    for (var i = 0; i < 31; i++) {
      var day = i + 1 < 10 ? '0' + (i + 1).toString() : (i + 1).toString();
      days.push(day);
    }
    return days;
  },

  monthsFiller: () => {
    return [
      { monthName: 'January', monthNumber: '01' },
      { monthName: 'February', monthNumber: '02' },
      { monthName: 'March', monthNumber: '03' },
      { monthName: 'April', monthNumber: '04' },
      { monthName: 'May', monthNumber: '05' },
      { monthName: 'June', monthNumber: '06' },
      { monthName: 'July', monthNumber: '07' },
      { monthName: 'August', monthNumber: '08' },
      { monthName: 'September', monthNumber: '09' },
      { monthName: 'October', monthNumber: '10' },
      { monthName: 'November', monthNumber: '11' },
      { monthName: 'December', monthNumber: '12' }
    ];
  },

  yearsFiller: function (includeAll) {
    var years = [];
    var currentYear = new Date().getFullYear();
    var yearStart = currentYear - 110;
    var yearEnd = currentYear - (includeAll ? 0 : 13);
    for (var i = yearStart; i < yearEnd; i++) {
      years.push(i + 1);
    }
    return years;
  },

  monthNameFromNumber: (number) => {
    var selectedMonth;
    DateHelper.monthsFiller().forEach((month) => {
      if (month.monthNumber == number) {
        selectedMonth = month;
      }
    });
    return selectedMonth.monthName;
  },

  convertDatesToFormat: function (schedule, format) {
    format = format || 'HH:mm';
    for (var field in schedule) {
      if (schedule[field] instanceof Date && schedule[field] != null) {
        schedule[field] = moment(schedule[field]).format(format);
        if (schedule[field] == 'Invalid date') {
          schedule[field] = null;
        }
      }
    }
    return schedule;
  },

  /**
   * Format a given date/time string as time only, with minutes ':00' removed
   *
   * @param {string} s - hh:mm format
   */
  formatTime: function (s) {
    return moment(s, 'h:mm a').format(s.match(/:00$/) ? 'h a' : 'h:mm a');
  },

  /**
   * Format a given date/time string as date with specified format or supplied default content on failure
   *
   * @param {string} date - ISO format
   * @param {string} format - format string supported by moment
   * @param {string} invalidDateOutput - content to display when the supplied date can't be parsed
   */
  formatDate: function (date, format, invalidDateOutput) {
    format = format || 'MMM DD, YYYY';
    invalidDateOutput = invalidDateOutput || 'N/A';
    if (date) {
      // NOTE: Parsing a date of birth string using `new Date(date)` doesn't work correctly for our DOB format due to timezone differences on the user's local machine.  `Date` assumes a date without a specified time is midnight UTC time which will result in presenting dates a day earlier for computers in the US timezones. `moment(date)` instead uses midnight in the local computer's timezone therefore presenting the correct date regardless of timezone.
      return moment(date).format(format);
    } else {
      return invalidDateOutput;
    }
  },

  /**
   * Get the abbreviation i18n key for the given day name
   *
   * @param {string} dayName
   * @returns string used in translation lookup
   */
  getAbbreviationForDayName: function (dayName) {
    var name = dayName[0].toUpperCase() + dayName.substring(1);
    for (var i = 0; i < this.weekDays.length; i++) {
      if (this.weekDays[i].name === name) {
        return this.weekDays[i].abbreviation;
      }
    }
    return null;
  },

  weekDays: [
    { name: 'Sunday', abbreviation: 'Sunday - abbreviation' },
    { name: 'Monday', abbreviation: 'Monday - abbreviation' },
    { name: 'Tuesday', abbreviation: 'Tuesday - abbreviation' },
    { name: 'Wednesday', abbreviation: 'Wednesday - abbreviation' },
    { name: 'Thursday', abbreviation: 'Thursday - abbreviation' },
    { name: 'Friday', abbreviation: 'Friday - abbreviation' },
    { name: 'Saturday', abbreviation: 'Saturday - abbreviation' }
  ],

  validateCalendar: function (day, month, year) {
    var dayValidate = '([1-9]|[12]d|3[01])';
    var monthValidate = '^(0?[1-9]|1[012])$';
    var yearValidate = '/^d{4}$/';

    if (day === null || !day.match(dayValidate)) {
      return { field: 'day', error: true, message: 'Please enter a valid day' };
    }

    if (month === null || !month.match(monthValidate)) {
      return { field: 'month', error: true, message: 'Please enter a valid month' };
    }

    if (year === null || year.length < 4 || +year === 0) {
      return { field: 'year', error: true, message: 'Please enter a valid year' };
    }

    if (!this.validateDay(+month, +day, +year)) {
      return { field: 'day', error: true, message: 'Please enter a valid day' };
    }

    if (new Date(year + '-' + month + '-' + day) > new Date()) {
      return { field: '', error: true, message: 'Date should not greater than current date' };
    }

    return { field: null, error: false, message: null };
  },

  validateDay: function (month, day, year) {
    // months are intended from 1 to 12
    var months31 = [1, 3, 5, 7, 8, 10, 12]; // months with 31 days
    var months30 = [4, 6, 9, 11]; // months with 30 days
    var months28 = [2]; // the only month with 28 days (29 if year isLeap)

    var isLeap = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

    var valid =
      (months31.indexOf(month) !== -1 && day <= 31) ||
      (months30.indexOf(month) !== -1 && day <= 30) ||
      (months28.indexOf(month) !== -1 && day <= 28) ||
      (months28.indexOf(month) !== -1 && day <= 29 && isLeap);

    return valid;
  }
};
