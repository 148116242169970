import publicRoutes from '@/public-routes.json';

const modalHelpers = {
  /*
    A convenience function for handling modal event-based callback functions that may (or may not) involve an asynchronous process.  For example, a 'Save' button that makes one or more API requests where the modal needs to wait for the response to determine the next action.

    @param toggleAwaitingState function that accepts a bool value to toggle the modal's state of awaiting a response
    @param handler function the handler to be called that may require awaiting a response.  Should throw an error to trigger the failure state.

    @return Promise
  */
  awaitHandler: (toggleAwaitingState, handler) => {
    toggleAwaitingState(true);
    let result = handler();
    if (result && typeof result.then === 'function') {
      return result
        .then((value) => {
          toggleAwaitingState(false);
          return value;
        })
        .catch((error) => {
          toggleAwaitingState(false);
          throw error;
        });
    } else {
      return new Promise((resolve) => {
        toggleAwaitingState(false);
        resolve(result);
      });
    }
  },

  handleNavigationOnModalClose: (router) => {
    const returnUrl = router?.query?.r;
    if (returnUrl) {
      window.location.href = returnUrl;
    } else {
      const isPublic = publicRoutes.routes.includes(window.location.pathname.split('/')[1]);
      if ((!isPublic || window.location.pathname == '/login') && window.location.href != '/') {
        window.location.href = '/';
      }
    }
  }
};

export default modalHelpers;
