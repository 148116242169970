import ODAS from '@/utils/api/odas';
import { bookmarkHelpers, resourceHelpers } from '@/utils/helpers';
import Fetchable from '@/utils/api/odrs/fetchable';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import find from 'lodash/find';

const Bookmarks = {
  addBookmark: function ({ fetchableType, fetchableId }) {
    return ODAS.post('/api/bookmarks', {
      bookmark: {
        fetchable_id: fetchableId,
        fetchable_type: fetchableType
      }
    }).then((response) => {
      if (response?.data?.bookmark) {
        bookmarkHelpers.addToBookmarkCache(response.data.bookmark);
      }
      // TODO: recreate Mixpanel and Log functionality - likely needs to be implemented elsewhere
      return response;
    });
  },
  addResourceToBookmarks: (bookmarks) => {
    let fetchableTypes = uniq(map(bookmarks, 'fetchable_type'));
    return Promise.all(
      fetchableTypes.map((fetchableType) => {
        return Bookmarks.loadResourcesFromBookmarks(
          bookmarks.filter((bk) => bk.fetchable_type === fetchableType && !bk.resource),
          fetchableType
        );
      })
    ).then((resources) => {
      return bookmarks.map((bookmark) => {
        const resource_collection =
          resources[fetchableTypes.indexOf(bookmark.fetchable_type)][resourceHelpers.collectionName(bookmark.fetchable_type)];
        if (!bookmark.resource) {
          bookmark.resource = find(resource_collection, { id: parseInt(bookmark.fetchable_id) });
        }
        return bookmark;
      });
    });
  },
  delete: ({ fetchableType, fetchableId }) => {
    return ODAS.delete('/api/bookmarks', { fetchable_id: fetchableId, fetchable_type: fetchableType }).then((response) => {
      bookmarkHelpers.removeFromBookmarkCache({ fetchableType, fetchableId });
      return response;
    });
  },
  getAllBookmarks: (params, force = false) => {
    if (!bookmarkHelpers.needsToBeLoaded() && !force) {
      // return the bookmark data in the same format as the ODAS API service
      return new Promise((resolve) => resolve({ data: { bookmarks: bookmarkHelpers.getCachedBookmarks() } }));
    } else {
      return ODAS.get('api/bookmarks', params).then((response) => {
        if (response?.data?.bookmarks) {
          bookmarkHelpers.cacheBookmarks(response.data.bookmarks);
        }
        return response;
      });
    }
  },
  loadResourcesFromBookmarks: (bookmarks, fetchableType, limitTo, params = {}) => {
    if (fetchableType == 'Collection') {
      // TODO: Fetch User Collections
    } else if (fetchableType == 'TopicPage') {
      // TODO: Fetch TopicPages
    } else {
      let titlesOnly = false;
      let resourceCollection = resourceHelpers.collectionName(fetchableType);
      let allBookmarkIds = resourceHelpers.getFetchableIds(bookmarks);
      let ids = allBookmarkIds[resourceCollection];
      if (!ids || ids?.length == 0) {
        return new Promise((resolve) => resolve([]));
      } else {
        if (limitTo) {
          titlesOnly = true;
          if (ids.length > limitTo) {
            ids = ids.slice(0, limitTo);
          }
        }

        if (titlesOnly) params.titles_only = true;
        return Fetchable.fetchResourcesById(resourceCollection, ids, params);
      }
    }
  }
};

export default Bookmarks;
