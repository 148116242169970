import { useState, useEffect } from 'react';
import { localStorageService } from '@/utils/local_storage';

export const useLocalStorage = (localStorageKey, defaultValue = '') => {
  const [value, setValue] = useState(localStorageService.get(localStorageKey) || defaultValue);

  const resetItem = () => {
    localStorageService.remove(localStorageKey);
  };

  useEffect(() => {
    localStorageService.set(localStorageKey, value);
  }, [localStorageKey, value]);

  return [value, setValue, resetItem];
};

/* Unused and can be deprecated */
export const useLocalStorageJSON = (localStorageKey) => {
  const [value, setValue] = useLocalStorage(localStorageKey);

  let jsonValue = undefined;
  try {
    jsonValue = JSON.parse(value);
  } catch (ex) {
    console.warn(ex);
  }

  const setJsonValue = (v) => setValue(JSON.stringify(v));

  return [jsonValue, setJsonValue];
};
