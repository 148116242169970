import ODAS from '@/utils/api/odas';

/**
 *  Existing angular implementation of these methods can be found in javascripts/controllers/clients/show.js.erb
 *  */
const AgencyConfiguration = {
  get: (params, callback) => {
    params = params || {};

    return ODAS.get('/api/organizations/configuration', params).then((response) => {
      if (callback) {
        return callback(response);
      } else {
        return response;
      }
    });
  },
  loadAgencyConfiguration: (params) => {
    params = params || {};
    return ODAS.get('/api/organizations/configuration', params);
  }
};

export default AgencyConfiguration;
