import { withTranslations } from '@/utils/Language';
import ui from '../../assets/i18n/ui/en.json';
import es_ui from '../../assets/i18n/ui/es.json';
import tags from '../../assets/i18n/tags/en.json';
import es_tags from '../../assets/i18n/tags/es.json';
import en_main from '../../assets/i18n/main/en.json';
import es_main from '../../assets/i18n/main/es.json';
import en_modals from '../../assets/i18n/modals/en.json';
import es_modal from '../../assets/i18n/modals/es.json';
const translations = {
  en: Object.assign({}, ui, tags, en_main, en_modals, {
    autocomplete_placeholder: 'Enter your location',
    autocomplete_subtext: 'Enter cross-streets, zipcode, city and state, or address. Or select a point on the map.',
    spanish: 'Español',
    find: 'Find',
    near: 'Near',
    search: 'Search',
    'Food pantries, housing search': 'Food pantries, housing search',
    'Address, city, or zip': 'Address, city, or zip',
    about: 'About',
    Back: 'Back',
    'add information': 'Add Information',
    help: 'Help',
    'Cookies Settings': 'Cookies Settings',
    'release notes': 'Release Notes',
    donate: 'Donate',
    blog: 'Blog',
    'Client Info': 'Client Info',
    Assessments: 'Assessments',
    'Frequently Asked Questions': 'Frequently Asked Questions',
    Remove: 'Remove',
    Share: 'Share',
    copyright: '© ${year} One Degree, a 501(c)(3) nonprofit.',
    'search by text': 'Search by text message available!',
    'anyone can search': 'Anyone can search for resources on One Degree by text message.',
    'text information': 'Text <strong>844-833-1334</strong> with what you\'re looking for – a word or phrase, like "food stamps" or "shelter".',
    assessments: 'Assessments',
    Collections: 'Collections',
    Resources: 'Resources',
    Dashboard: 'Dashboard',
    Clients: 'Clients',
    'My Collections': 'My Collections',
    'My Bookmarks': 'My Bookmarks',
    Groups: 'Groups',
    'Browse All Categories': 'Browse All Categories',
    Menu: 'Menu',
    Bookmark: 'Bookmark',
    Bookmarked: 'Bookmarked',
    'Add to another collection': 'Add to another collection',
    'One Degree dashboard': 'One Degree Dashboard',
    'My Assessments': 'My Assessments',
    'Saved Collections': 'Saved Collections',
    'My Documents': 'My Documents',
    'Pick date': 'Pick date',
    'LA County Assist': 'LA County Assist',
    'Open LA County Assist': 'Open LA County Assist',
    'Are you experiencing or at risk of homelessness in LA county?': 'Are you experiencing or at risk of homelessness in LA county?',
    'Please enter a valid month': 'Please enter a valid month',
    'Please enter a valid date': 'Please enter a valid date',
    'Please enter a valid year': 'Please enter a valid year',
    'Date should not be greater than current date': 'Date should not be greater than current date',
    'My Clients': 'My Clients',
    'All Clients': 'All Clients',
    'All Referrals': 'All Referrals',
    'Client name': 'Client name',
    Name: 'Name',
    'External ID': 'External ID',
    Created: 'Created',
    'One Degree member': 'One Degree member',
    'Date of conversion': 'Date of conversion',
    'Referral for': 'Referral for',
    'Referral ID': 'Referral ID',
    'Automatically find my location': 'Automatically find my location',
    'Log out': 'Log out',
    'My clients & referrals': 'My clients & referrals',
    'My Groups': 'My Groups',
    'My groups': 'My groups',
    'Edit organization': 'Edit organization',
    'Entire organization': 'Entire organization',
    Facilities: 'Facilities',
    Staff: 'Staff',
    Reports: 'Reports',
    'My Organization': 'My Organization',
    'View organization page': 'View organization page',
    'Manage organization users': 'Manage organization users',
    'View reports': 'View reports',
    'Inbound clients': 'Inbound clients',
    'Inbound referrals': 'Inbound referrals',
    'Browse Resources': 'Browse Resources',
    Show: 'Show',
    Hide: 'Hide',
    'See more': 'See more',
    'You might be interested in___': 'You might be interested in...',
    'You are an Admin member affiliated with': 'You are an Admin member affiliated with',
    'You are a Pro member affiliated with': 'You are a Pro member affiliated with',
    'You are a Pro member.': 'You are a Pro member.',
    'You are a community member of One Degree.': 'You are a community member of One Degree.',
    'Do you work at a non-profit?': 'Do you work at a non-profit?',
    'Explore resources by category': 'Explore resources by category',
    'Resources by category': 'Resources by category',
    'Helpful Links': 'Helpful Links',
    'Do you help clients find resources? Are you a social worker or case manager? If so, then click Yes to get more One Degree features to help you with your work':
      'Do you help clients find resources? Are you a social worker or case manager? If so, then click Yes to get more One Degree features to help you with your work',
    'Text keywords like  "food stamps" or "shelter"  to <a href="sms://+1844-833-1334"> 844-833-1334 </a> to find resources using text/SMS':
      'Text keywords like  "food stamps" or "shelter"  to <a href="sms://+1844-833-1334"> 844-833-1334 </a> to find resources using text/SMS',
    'Enter a location': 'Enter a location',
    'Help us expand our services': 'Help us expand our services',
    'Remove all filters': 'Eliminar todo los filtros',
    'Check the spelling of your search': 'Check the spelling of your search',
    'Try a search with more general words, like housing instead of apartments':
      'Try a search with more general words, like "housing" instead of "apartments"',
    'Help us expand our services': 'Help us expand our services',
    Page: 'Page',
    of: 'of',
    'Search staff': 'Search staff',
    Near: 'near',
    "Sorry, we couldn't find any": "Sorry, we couldn't find any",
    'try browsing our categories:': 'try browsing our categories:',
    'Here are a few things that might help:': 'Here are a few things that might help:',
    'Remove all filters': 'Remove all filters',
    'Our service areas': 'Our service areas',
    'One Degree is currently available in': 'One Degree is currently available in:',
    'California: San Francisco Bay Area, Los Angeles County': '',
    'Florida: Gainesville': 'Florida: Gainesville',
    'Michigan: Northwest Detroit': 'Michigan: Northwest Detroit',
    'New Mexico: Southwest New Mexico': 'New Mexico: Southwest New Mexico',
    'New York: New York City': 'New York: New York City',
    'See all Organizations matching': 'See all Organizations matching',
    'See all Collections matching': 'See all Collections matching',
    'Give us your feedback': 'Give us your feedback',
    'Your voice matters. Answer some quick questions for us so we can make One Degree better.':
      'Your voice matters. Answer some quick questions for us so we can make One Degree better.',
    Closed: 'Closed',
    'Email (Required)': 'Email (Required)',
    'First name (Required)': 'First name (Required)',
    'Last name (Required)': 'Last name (Required)',
    'Title (Optional)': 'Title (Optional)',
    'Generating PDF...': 'Generating PDF...',
    referral_follow_up_one:
      'Thank you for referring a resource to ${firstName} ${lastName}! Make sure to follow up and support ${firstName} along the way.',
    referral_follow_up_multiple:
      'Thank you for referring ${resourceCount} resources to ${firstName} ${lastName}! Make sure to follow up and support ${firstName} along the way.',
    'Download PDF to print': 'Download PDF to print',
    'Referral sent!': 'Referral sent!',
    'View Xs client page and referral': "View ${firstName}'s client page and referral"
  }),
  es: Object.assign({}, es_ui, es_tags, es_main, es_modal, {
    autocomplete_placeholder: 'Entre su ubicación',
    autocomplete_subtext: 'Ingrese cruces de calles, zipcode, ciudad y estado, o dirección. O seleccione un punto en el mapa.',
    english: 'English',
    find: 'Buscar',
    near: 'En',
    search: 'Buscar',
    'Food pantries, housing search': 'Despensario de alimentos, búsqueda de vivienda',
    'Address, city, or zip': 'Dirección, ciudad o código postal',
    about: 'Acerca de',
    Back: 'Atrás',
    'add information': 'Añadir información',
    'Cookies Settings': 'Configuración de Cookies',
    'release notes': 'Notas de la Versión',
    help: 'Ayuda',
    donate: 'Donar',
    blog: 'Blog',
    'Client Info': 'Información del cliente',
    Assessments: 'Evaluaciones',
    'Frequently Asked Questions': 'Preguntas más frecuentes',
    Remove: 'Remover',
    Share: 'Compartir',
    copyright: '© ${year} One Degree, una organizacíon 501(c)(3) sin fines de lucro.',
    'search by text': 'Búsqueda por mensaje de texto disponible!',
    'anyone can search': 'Cualquier persona puede buscar recursos en One Degree por mensaje de texto.',
    'text information':
      'Envie mensaje de texto al <strong>844-833-1334</strong> con lo que está buscando – una palabra o frase, como "cupones de alimentos" o "refugio".',
    assessments: 'Evaluaciones',
    Collections: 'Colecciones',
    Resources: 'Recursos',
    Dashboard: 'Tablero',
    Clients: 'Clientes',
    'My Collections': 'Mis Colecciones',
    'My Bookmarks': 'Mis favoritos',
    Groups: 'Grupos',
    'Browse All Categories': 'Navegar todas las categorías',
    Menu: 'Menú',
    Bookmark: 'Marcar',
    Bookmarked: 'Marcado',
    'Add to another collection': 'Añadir a otra colección',
    'One Degree dashboard': 'Tablero',
    'Training for Professionals': 'Entrenamiento para profesionales',
    'My Assessments': 'Mis evaluaciones',
    'Saved Collections': 'Colecciones guardadas',
    'My Documents': 'Mis documentos',
    'Pick date': 'Elegir fecha',
    'LA County Assist': 'LA County Assist',
    'Open LA County Assist': 'Abrir LA County Assist',
    'Are you experiencing or at risk of homelessness in LA county?':
      '¿Está sin hogar o en riesgo de quedarse sin hogar en el condado de Los Ángeles?',
    'Please enter a valid month': 'Por favor, introduzca un mes válido',
    'Please enter a valid date': 'Por favor introduzca una fecha valida',
    'Please enter a valid year': 'Por favor, introduzca un año válido',
    'Date should not be greater than current date': 'La fecha no debe ser posterior a la fecha actual',
    'My Clients': 'Mis clientes',
    'All Clients': 'Todos los clientes',
    'All Referrals': 'Todas las referencias',
    'Client name': 'Nombre del cliente',
    Name: 'Nombre',
    'External ID': 'ID de cliente externo',
    Created: 'Creado',
    'One Degree member': 'Miembro de One Degree',
    'Date of conversion': 'Fecha de conversión',
    'Referral for': 'Referencia para',
    'Referral ID': 'ID de referencia',
    'Automatically find my location': 'Establezca automáticamente mi ubicación',
    'Log out': 'Finalizar sesión',
    'My clients & referrals': 'Mis clientes y referencias',
    'My Groups': 'Mis grupos',
    'My groups': 'Mis grupos',
    'Edit organization': 'Editar organización',
    'Entire organization': 'Toda la organización',
    Facilities: 'Instalaciones',
    Staff: 'Personal',
    Reports: 'Informes',
    'My Organization': 'Mi organización',
    'View organization page': 'Ver página de la organización',
    'Manage organization users': 'Administrar usuarios de la organización',
    'View reports': 'Ver informes',
    'Inbound clients': 'Clientes entrantes',
    'Inbound referrals': 'Referencias entrantes',
    'My Groups': 'Mis grupos',
    'Browse Resources': 'Navegar recursos',
    Show: 'Mostrar',
    Hide: 'Esconder',
    'See more': 'Ver más',
    'You might be interested in___': 'Te podría interesar...',
    Hello: 'Hola',
    'You are an Admin member affiliated with': 'Usted es un miembro administrador afiliado a',
    'You are a Pro member affiliated with': 'Usted es un miembro Pro afiliado',
    'You are a Pro member': 'Usted es un miembro Pro.',
    'You are a community member of One Degree.': 'Usted es miembro de la comunidad de One Degree.',
    'Do you work at a non-profit?': '¿Trabaja en una organización sin fines de lucro?',
    'Explore resources by category': 'Explorar recursos por categoría',
    'Resources by category': 'Recursos por categoría',
    'Helpful Links': 'Enlaces',
    'Do you help clients find resources? Are you a social worker or case manager? If so, then click Yes to get more One Degree features to help you with your work':
      '¿Ayuda a clientes a encontrar recursos? ¿Es trabajador social o administrador de casos? Si es así, haga clic en Sí para obtener más funciones de One Degree que pueden ayudarle con su trabajo.',
    'Text keywords like  "food stamps" or "shelter"  to <a href="sms://+1844-833-1334"> 844-833-1334 </a> to find resources using text/SMS':
      'Escriba "refugio" o "cupones de alimentos" u otras palabras clave y envíelas a <a href="sms://+1844-833-1334">844-833-1334</a> para encontrar recursos usando SMS.',
    'Enter a location': 'Ingrese su ubicación',
    'Help us expand our services': 'Ayúdenos a expandir nuestros servicios',
    'Remove all filters': 'Eliminar todo los filtros',
    'Check the spelling of your search': 'Verifique si deletreó su búsqueda correctamente',
    'Try a search with more general words, like housing instead of apartments':
      'Intente usar palabras más generales para su búsqueda, como "vivienda" en vez de "departamentos"',
    'Help us expand our services': 'Ayúdenos a expandir nuestros servicios',
    Page: 'Página',
    of: 'de',
    'Search staff': 'Buscar personal',
    Near: 'cerca',
    "Sorry, we couldn't find any": 'Lo sentimos, no pudimos encontrar cualquier',
    'try browsing our categories:': 'Intente navegar nuestras categorías de programas:',
    'Here are a few things that might help:': 'Aquí hay algunas cosas que podrían ayudar',
    'Remove all filters': 'Eliminar todo los filtros',
    'Our service areas': 'Nuestras áreas de servicio',
    'One Degree is currently available in': 'One Degree está disponible en:',
    'California: San Francisco Bay Area, Los Angeles County': 'California: Área de la Bahía de SF y Condado de LA',
    'Florida: Gainesville': 'Florida: Gainesville',
    'Michigan: Northwest Detroit': 'Michigan: Noroeste de Detroit',
    'New Mexico: Southwest New Mexico': 'New Mexico: Suroeste de Nuevo México',
    'New York: New York City': 'New York: la ciudad de Nueva York',
    'See all Organizations matching': 'Ver todas las organizaciones que coinciden',
    'See all Collections matching': 'Ver todas las colecciones que coinciden',
    'Give us your feedback': 'Denos su opinión',
    'Your voice matters. Answer some quick questions for us so we can make One Degree better.':
      'Su voz importa. Responda unas preguntas rápidas para poder mejorar One Degree.',
    Closed: 'Cerrado',
    'Email (Required)': 'Correo electrónico (Requerido)',
    'First name (Required)': 'Primer nombre (Requerido)',
    'Last name (Required)': 'Apellido (Requerido)',
    'Title (Optional)': 'Título (opcional)',
    'Generating PDF...': 'Generando PDF...',
    referral_follow_up_one:
      '¡Gracias por recomendar un recurso a ${firstName} ${lastName}! Asegúrate de hacer un seguimiento y apoyar a ${firstName} en el camino.',
    referral_follow_up_multiple:
      '¡Gracias por recomendar ${resourceCount} recursos a ${firstName} ${lastName}! Asegúrate de hacer un seguimiento y apoyar a ${firstName} en el camino.',
    'Download PDF to print': 'Descargar archivo PDF para imprimir',
    'Referral sent!': '¡Referencia enviada!',
    'View Xs client page and referral': 'Ver la página del cliente de ${firstName} y referencia'
  })
};

export default withTranslations(translations);
