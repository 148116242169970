const propertyHelpers = {
  getServiceProviderAreaPropertyFromZipcode: (zipcode) => {
    if (!zipcode || !propertyHelpers.zipcode_spas[zipcode]) {
      return false;
    }
    return 'service-provider-area-los-angeles-' + propertyHelpers.zipcode_spas[zipcode];
  },
  zipcode_spas: {
    93510: 1,
    93534: 1,
    93535: 1,
    93536: 1,
    93543: 1,
    93544: 1,
    93550: 1,
    93551: 1,
    93552: 1,
    93553: 1,
    93591: 1,
    93539: 1,
    93590: 1,
    93599: 1,
    93586: 1,
    93584: 1,
    90290: 2,
    91020: 2,
    91040: 2,
    91042: 2,
    91201: 2,
    91202: 2,
    91203: 2,
    91204: 2,
    91205: 2,
    91206: 2,
    91207: 2,
    91208: 2,
    91214: 2,
    91301: 2,
    91302: 2,
    91303: 2,
    91304: 2,
    91306: 2,
    91307: 2,
    91311: 2,
    91316: 2,
    91321: 2,
    91324: 2,
    91325: 2,
    91326: 2,
    91330: 2,
    91331: 2,
    91335: 2,
    91340: 2,
    91342: 2,
    91343: 2,
    91344: 2,
    91345: 2,
    91350: 2,
    91351: 2,
    91352: 2,
    91354: 2,
    91355: 2,
    91356: 2,
    91364: 2,
    91367: 2,
    91371: 2,
    91381: 2,
    91384: 2,
    91387: 2,
    91390: 2,
    91401: 2,
    91402: 2,
    91403: 2,
    91405: 2,
    91406: 2,
    91411: 2,
    91423: 2,
    91436: 2,
    91501: 2,
    91502: 2,
    91504: 2,
    91505: 2,
    91506: 2,
    91521: 2,
    91522: 2,
    91523: 2,
    91601: 2,
    91602: 2,
    91604: 2,
    91605: 2,
    91606: 2,
    91607: 2,
    91608: 2,
    93532: 2,
    91618: 2,
    91614: 2,
    91609: 2,
    91357: 2,
    91365: 2,
    91407: 2,
    91404: 2,
    91408: 2,
    91495: 2,
    91413: 2,
    91603: 2,
    91616: 2,
    91496: 2,
    91327: 2,
    91353: 2,
    91346: 2,
    91393: 2,
    91499: 2,
    91043: 2,
    91341: 2,
    91041: 2,
    91503: 2,
    91372: 2,
    91376: 2,
    91226: 2,
    91221: 2,
    91046: 2,
    91222: 2,
    91322: 2,
    91383: 2,
    91382: 2,
    91310: 2,
    91385: 2,
    91012: 2,
    91309: 2,
    91526: 2,
    91507: 2,
    91333: 2,
    91510: 2,
    91224: 2,
    91305: 2,
    91308: 2,
    91225: 2,
    91426: 2,
    91328: 2,
    91416: 2,
    91313: 2,
    91334: 2,
    91209: 2,
    91337: 2,
    91396: 2,
    91395: 2,
    91508: 2,
    91617: 2,
    91392: 2,
    91410: 2,
    91615: 2,
    91610: 2,
    91470: 2,
    91409: 2,
    91380: 2,
    91482: 2,
    91612: 2,
    91611: 2,
    91021: 2,
    91386: 2,
    91329: 2,
    91412: 2,
    91394: 2,
    91210: 2,
    91001: 3,
    91006: 3,
    91007: 3,
    91008: 3,
    91010: 3,
    91011: 3,
    91016: 3,
    91024: 3,
    91030: 3,
    91101: 3,
    91103: 3,
    91104: 3,
    91105: 3,
    91106: 3,
    91107: 3,
    91108: 3,
    91123: 3,
    91702: 3,
    91706: 3,
    91711: 3,
    91722: 3,
    91723: 3,
    91724: 3,
    91731: 3,
    91732: 3,
    91733: 3,
    91740: 3,
    91741: 3,
    91744: 3,
    91745: 3,
    91746: 3,
    91748: 3,
    91750: 3,
    91754: 3,
    91755: 3,
    91765: 3,
    91766: 3,
    91767: 3,
    91768: 3,
    91770: 3,
    91773: 3,
    91775: 3,
    91776: 3,
    91780: 3,
    91789: 3,
    91790: 3,
    91791: 3,
    91792: 3,
    91801: 3,
    91803: 3,
    93563: 3,
    91769: 3,
    91793: 3,
    91747: 3,
    91023: 3,
    91114: 3,
    91189: 3,
    91199: 3,
    91115: 3,
    91184: 3,
    91129: 3,
    91182: 3,
    91117: 3,
    91121: 3,
    91118: 3,
    91804: 3,
    91896: 3,
    91899: 3,
    91756: 3,
    91735: 3,
    91017: 3,
    91771: 3,
    91772: 3,
    91185: 3,
    91066: 3,
    91025: 3,
    91734: 3,
    91788: 3,
    91778: 3,
    91077: 3,
    91116: 3,
    91124: 3,
    91716: 3,
    91009: 3,
    91749: 3,
    91188: 3,
    91802: 3,
    91003: 3,
    91031: 3,
    91102: 3,
    91110: 3,
    91109: 3,
    91714: 3,
    91715: 3,
    91126: 3,
    91125: 3,
    90004: 4,
    90005: 4,
    90006: 4,
    90012: 4,
    90013: 4,
    90014: 4,
    90015: 4,
    90017: 4,
    90019: 4,
    90020: 4,
    90021: 4,
    90023: 4,
    90026: 4,
    90027: 4,
    90028: 4,
    90029: 4,
    90031: 4,
    90032: 4,
    90033: 4,
    90036: 4,
    90038: 4,
    90039: 4,
    90041: 4,
    90042: 4,
    90046: 4,
    90048: 4,
    90057: 4,
    90065: 4,
    90068: 4,
    90069: 4,
    90071: 4,
    90081: 4,
    90079: 4,
    90088: 4,
    90087: 4,
    90060: 4,
    90086: 4,
    90054: 4,
    90030: 4,
    90051: 4,
    90053: 4,
    90070: 4,
    90075: 4,
    90076: 4,
    90078: 4,
    90093: 4,
    90072: 4,
    90050: 4,
    90010: 4,
    90074: 4,
    90055: 4,
    90096: 4,
    90084: 4,
    90090: 4,
    90024: 5,
    90025: 5,
    90034: 5,
    90035: 5,
    90045: 5,
    90049: 5,
    90056: 5,
    90064: 5,
    90066: 5,
    90067: 5,
    90073: 5,
    90077: 5,
    90094: 5,
    90095: 5,
    90210: 5,
    90211: 5,
    90212: 5,
    90230: 5,
    90232: 5,
    90263: 5,
    90265: 5,
    90272: 5,
    90291: 5,
    90292: 5,
    90293: 5,
    90401: 5,
    90402: 5,
    90403: 5,
    90404: 5,
    90405: 5,
    90209: 5,
    90213: 5,
    90189: 5,
    90233: 5,
    90311: 5,
    90296: 5,
    90295: 5,
    90264: 5,
    90409: 5,
    90410: 5,
    90411: 5,
    90406: 5,
    90407: 5,
    90312: 5,
    90408: 5,
    90080: 5,
    90231: 5,
    90009: 5,
    90083: 5,
    90294: 5,
    90001: 6,
    90002: 6,
    90003: 6,
    90007: 6,
    90008: 6,
    90011: 6,
    90016: 6,
    90018: 6,
    90037: 6,
    90043: 6,
    90047: 6,
    90059: 6,
    90061: 6,
    90062: 6,
    90089: 6,
    90220: 6,
    90221: 6,
    90222: 6,
    90262: 6,
    90723: 6,
    90082: 6,
    90223: 6,
    90224: 6,
    90099: 6,
    90052: 6,
    90022: 7,
    90040: 7,
    90058: 7,
    90063: 7,
    90201: 7,
    90240: 7,
    90241: 7,
    90242: 7,
    90255: 7,
    90270: 7,
    90280: 7,
    90601: 7,
    90602: 7,
    90603: 7,
    90604: 7,
    90605: 7,
    90606: 7,
    90638: 7,
    90639: 7,
    90640: 7,
    90650: 7,
    90660: 7,
    90670: 7,
    90701: 7,
    90703: 7,
    90706: 7,
    90712: 7,
    90713: 7,
    90715: 7,
    90716: 7,
    90755: 7,
    90651: 7,
    90652: 7,
    90202: 7,
    90610: 7,
    90239: 7,
    90607: 7,
    90608: 7,
    90661: 7,
    90637: 7,
    90662: 7,
    90671: 7,
    90707: 7,
    90702: 7,
    90714: 7,
    90091: 7,
    90609: 7,
    90711: 7,
    90044: 8,
    90245: 8,
    90247: 8,
    90248: 8,
    90249: 8,
    90250: 8,
    90254: 8,
    90260: 8,
    90266: 8,
    90274: 8,
    90275: 8,
    90277: 8,
    90278: 8,
    90301: 8,
    90302: 8,
    90303: 8,
    90304: 8,
    90305: 8,
    90501: 8,
    90502: 8,
    90503: 8,
    90504: 8,
    90505: 8,
    90506: 8,
    90704: 8,
    90710: 8,
    90717: 8,
    90731: 8,
    90732: 8,
    90744: 8,
    90745: 8,
    90746: 8,
    90747: 8,
    90802: 8,
    90803: 8,
    90804: 8,
    90805: 8,
    90806: 8,
    90807: 8,
    90808: 8,
    90810: 8,
    90813: 8,
    90814: 8,
    90815: 8,
    90822: 8,
    90840: 8,
    90748: 8,
    90733: 8,
    90842: 8,
    90847: 8,
    90848: 8,
    90846: 8,
    90844: 8,
    90834: 8,
    90261: 8,
    90310: 8,
    90267: 8,
    90835: 8,
    90833: 8,
    90899: 8,
    90510: 8,
    90509: 8,
    90749: 8,
    90801: 8,
    90853: 8,
    90734: 8,
    90809: 8,
    90251: 8,
    90831: 8,
    90309: 8,
    90895: 8,
    90832: 8,
    90507: 8,
    90308: 8,
    90307: 8,
    90508: 8,
    90306: 8
  }
};

export default propertyHelpers;
