import { useEffect, useState } from 'react';
import { Honeybadger } from '@honeybadger-io/react';

export const useHoneybadger = () => {
  const [honeybadger, setHoneybadger] = useState(false);

  const hbConfig = {
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.NODE_ENV
    // reportData: true // uncomment to enable reporting for dev environment
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' || hbConfig.reportData) {
      setHoneybadger(Honeybadger?.configure(hbConfig));
    }
  }, []);

  const notifyHB = (error, options = {}) => {
    honeybadger?.notify?.(error, options);
  };

  const setContext = (contextObject) => {
    honeybadger?.setContext?.(contextObject);
  };

  return {
    notifyHoneybadger: notifyHB,
    setHoneybadgerContext: setContext
  };
};

export default useHoneybadger;
