import publicRoutes from '@/public-routes.json';

const themeHelpers = {
  getTheme: (router) => {
    if (router.query.theme) {
      return router.query.theme;
    }
    let theme;
    const pageRoot = router.pathname.split('/')[1]; // router.pathname is something like /stayhousedbayarea or /clients
    if (pageRoot == publicRoutes.lacountyassist) {
      theme = 'lahip';
    } else if (pageRoot == publicRoutes.allhome) {
      theme = 'allhome';
    } else if (pageRoot == publicRoutes.familyresourcefinder) {
      theme = 'familyresourcefinder';
    } else {
      theme = 'prime';
    }
    return theme;
  }
};

export default themeHelpers;
