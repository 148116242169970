import ODAS from '@/utils/api/odas';
import Language from '@/utils/Language';
import { resourceHelpers } from '@/utils/helpers';

const ALLOWED_SECOND_ORIGIN_TYPES = {
  Prapare: 'Prapare',
  PEARLS: 'PEARLS'
};

const Access = {
  getAllAccessEvents: (fetchable_type, params) => {
    Object.assign(params, { with_resources: true, fetchable_type: fetchable_type, locale: Language.getCurrentLanguage() });
    return ODAS.get('/api/access_events', params).then((response) => {
      if (response?.data) {
        response.data = response.data.map((accessEvent) => {
          accessEvent.resource = resourceHelpers.replaceWithTranslations(accessEvent.resource);
          return accessEvent;
        });
      }
      return response;
    });
  },

  getByResource: ({ resource }) => {
    const params = {
      fetchable_type: resource.resource_type,
      fetchable_id: resource.id
    };
    return ODAS.get('/api/access_events', params);
  },
  createOrUpdate: ({ resource, status, direct = false, secondOrigin = null }) => {
    const accessEvent = {
      fetchable_type: resource.resource_type,
      fetchable_id: resource.id,
      status: status,
      direct_access: direct
    };
    let invitation = null;

    if (secondOrigin && ALLOWED_SECOND_ORIGIN_TYPES[secondOrigin.origin_type]) {
      invitation = secondOrigin.invitation || null;
      accessEvent.origins = [{ origin_type: 'Self' }, { origin_type: ALLOWED_SECOND_ORIGIN_TYPES[secondOrigin.origin_type] }];
    }

    const params = { access_event: accessEvent };
    if (invitation) params.invitation = invitation; // id & token if this is coming from an assessment invitation

    return ODAS.post('/api/access_events', params);
  },
  delete: ({ accessEventId }) => {
    return ODAS.delete('api/access_events/' + accessEventId);
  },
  markReviewed: function (id) {
    let accessEvent = {
      reviewed: true
    };
    return ODAS.put('/api/access_events/' + id, { access_event: accessEvent });
  },
  getById: (id, params) => {
    return ODAS.get('/api/access_events/' + id, params);
  }
};

export const AccessEventStatuses = () => {
  return {
    planned: 'planned'
  };
};

export default Access;
