/**
 * Created by asafdav on 15/05/14.
 */

import cloneDeep from 'lodash/cloneDeep';

const CSV = {
  EOL: '\r\n',
  BOM: '\ufeff',
  specialChars: {
    '\\t': '\t',
    '\\b': '\b',
    '\\v': '\v',
    '\\f': '\f',
    '\\r': '\r'
  },

  /**
   * Stringify one field
   * @param data
   * @param options
   * @returns {*}
   */
  stringifyField: (data, options) => {
    if (options.decimalSep === 'locale' && CSV.isFloat(data)) {
      return data.toLocaleString();
    }

    if (options.decimalSep !== '.' && CSV.isFloat(data)) {
      return data.toString().replace('.', options.decimalSep);
    }

    if (typeof data === 'string') {
      data = data.replace(/"/g, '""'); // Escape double qoutes

      if (options.quoteStrings || data.indexOf(',') > -1 || data.indexOf('\n') > -1 || data.indexOf('\r') > -1) {
        data = options.txtDelim + data + options.txtDelim;
      }

      return data;
    }

    if (typeof data === 'boolean') {
      return data ? 'TRUE' : 'FALSE';
    }

    return data;
  },

  /**
   * Helper function to check if input is float
   * @param input
   * @returns {boolean}
   */
  isFloat: (input) => {
    return +input === input && (!isFinite(input) || Boolean(input % 1));
  },

  /**
   * Creates a csv from a data array
   * @param data
   * @param options
   *  * header - Provide the first row (optional)
   *  * fieldSep - Field separator, default: ',',
   *  * addByteOrderMarker - Add Byte order mark, default(false)
   * @param callback
   */
  stringify: (data, options = { txtDelim: '"' }) => {
    let csv = '';
    let csvContent = '';

    //responseData = angular.copy(responseData);//moved to row creation
    // Check if there's a provided header array
    if (typeof options.header !== 'undefined' && Array.isArray(options.header)) {
      let encodingArray, headerString;

      encodingArray = [];
      options.header.forEach((title, key) => {
        encodingArray.push(CSV.stringifyField(title, options));
      });

      headerString = encodingArray.join(options.fieldSep ? options.fieldSep : ',');
      csvContent += headerString + CSV.EOL;
    }

    let arrData = [];

    if (Array.isArray(data)) {
      arrData = data;
    } else if (typeof data === 'function') {
      arrData = data();
    }

    // Check if using keys as labels
    if (typeof options.label !== 'undefined' && options.label && typeof options.label === 'boolean') {
      let labelArray, labelString;

      labelArray = [];

      let iterator = !!options.columnOrder ? options.columnOrder : arrData[0];
      iterator.forEach((value, label) => {
        let val = !!options.columnOrder ? value : label;
        labelArray.push(CSV.stringifyField(val, options));
      });
      labelString = labelArray.join(options.fieldSep ? options.fieldSep : ',');
      csvContent += labelString + CSV.EOL;
    }

    arrData.forEach((oldRow, index) => {
      let row = cloneDeep(arrData[index]);
      let dataString, infoArray;

      infoArray = [];

      let iterator = !!options.columnOrder ? options.columnOrder : row;
      iterator.forEach((field, key) => {
        let val = !!options.columnOrder ? row[field] : field;
        infoArray.push(CSV.stringifyField(val, options));
      });

      dataString = infoArray.join(options.fieldSep ? options.fieldSep : ',');
      csvContent += index < arrData.length ? dataString + CSV.EOL : dataString;
    });

    // Add BOM if needed
    if (options.addByteOrderMarker) {
      csv += CSV.BOM;
    }

    // Append the content and resolve.
    csv += csvContent;
    return csv;
  },

  /**
   * Helper function to check if input is really a special character
   * @param input
   * @returns {boolean}
   */
  isSpecialChar: (input) => {
    return CSV.specialChars[input] !== undefined;
  },

  /**
   * Helper function to get what the special character was supposed to be
   * since Angular escapes the first backslash
   * @param input
   * @returns {special character string}
   */
  getSpecialChar: (input) => {
    return CSV.specialChars[input];
  }
};

export default CSV;
