import lang, { withTranslations } from '@/utils/Language';
import ui from '@/assets/i18n/ui';

const translations = {
  en: Object.assign({}, ui.en, {
    'Edit document': 'Edit document',
    'View document': 'View document',
    'Document updated successfully.': 'Document updated successfully.',
    'Document update failed, please try again.': 'Document update failed, please try again.',
    'Upload settings': 'Upload settings',
    'Upload document': 'Upload document',
    'Your documents are loading': 'Your documents are loading',
    'Document Name': 'Document Name',
    'Document name': 'Document name',
    'Uploaded On': 'Uploaded On',
    'Uploaded By': 'Uploaded By',
    Actions: 'Actions',
    'Your document will begin downloading to your device shortly.': 'Your document will begin downloading to your device shortly.',
    'Document successfully downloaded.': 'Document successfully downloaded.',
    'Sorry! The download was unsuccessful.': 'Sorry! The download was unsuccessful.',
    'Want to keep track of your important documents in one place? Upload your documents and they’ll be safely stored and easily accessible later.':
      'Want to keep track of your important documents in one place? Upload your documents and they’ll be safely stored and easily accessible later.',
    'Uploaded at by uploader': 'Uploaded ${at} by ${uploader}',
    'Shared with count others': "Shared with  ${count} ${count > 1 ? 'others' : 'other'}",
    'Not shared': 'Not shared',
    'Document loading failed.': 'Document loading failed.',
    'A document name is required': 'A document name is required',
    Self: 'You',
    'Uploaded by': 'Uploaded by',
    'Shared with': 'Shared with',
    'Shared With': 'Shared With',
    'No one has access yet': 'No one has access yet',
    'To start using Documents, please verify your account with your email or cell phone number.':
      'To start using <strong>Documents</strong>, please verify your account with your email or cell phone number.',
    'Please email us at help@1degree.org or text us anytime at (800) 484-0126. We will respond within 1-3 business days.':
      "Please email us at <a href='mailto:help@1degree.org'>help@1degree.org</a> or text us anytime at <a href='tel:+18004840126'>(800) 484-0126</a>. We will respond within 1-3 business days.",
    'Your email': 'Your email',
    'Your cell phone': 'Your cell phone',
    Update: 'Update',
    'file too large': 'Your file is too large',
    'file too large message': 'This file is too large. Documents need to be under 5MB. Try uploading a different document.',
    'file not supported': 'File type not supported',
    'file not supported message':
      'This file type is not supported. Documents can only be jpeg, png, or pdf formats. Try uploading a different document.',
    'Send email verification': 'Send email verification',
    'Send text verification': 'Send text verification',
    'Email saved successfully.': 'Email saved successfully.',
    'Cell phone saved successfully.': 'Cell phone saved successfully.',
    'Email verification sent!': 'Email verification sent!',
    'Cell phone verification sent!': 'Cell phone verification sent!',
    'Please login to view your documents.': 'Please log in or sign up to view your documents.',
    "Upload important documents like your ID, utility bills, or Social Security card to easily access later. They'll be safely stored and kept private and you can control who you share them with.":
      "<strong>Upload important documents</strong> like your ID, utility bills, or Social Security card to easily access later. They'll be <strong>safely stored</strong> and <strong>kept private</strong> and you can <strong>control who you share them with</strong>.",
    "Upload important documents to easily access later. They'll be safely stored and kept private and you can control who you share them with.":
      "<strong>Upload important documents</strong> to easily access later. They'll be <strong>safely stored</strong> and <strong>kept private</strong> and you can <strong>control who you share them with</strong>.",
    'Documents need to be a PDF, PNG, or JPG and less than 5MB in size.': 'Documents need to be a PDF, PNG, or JPG and less than 5MB in size.',
    'Upload a PDF, PNG, or JPG that is less than 5MB in size.': 'Upload a PDF, PNG, or JPG that is less than 5MB in size.',
    "Client hasn't given you access to view any of their documents. But you can still upload documents to client's account.":
      "${client} hasn't given you access to view any of their documents. But you can still upload documents to ${client}'s account.",
    log_in_sign_up: 'Log in/Sign up',
    login: 'Login',
    'Have issues verifying your account?': 'Have issues verifying your account?',
    'Are you sure you want to remove your access to this document?': 'Are you sure you want to remove your access to this document?',
    'You will no longer be able to view this document. This can be helpful if a document was shared with you by mistake.':
      'You will no longer be able to view this document. This can be helpful if a document was shared with you by mistake.',
    'Remove my access': 'Remove my access',
    'Yes, remove access': 'Yes, remove access',
    "No, don't remove access": "No, don't remove access",
    'Access successfully removed.': 'Access successfully removed.',
    'Failed to remove access. Please try again.': 'Failed to remove access. Please try again.',
    'Failed to be removed access. Please try again.': 'Failed to be removed access. Please try again.',
    'To upload documents or view documents has given you access to, you must agree to the following:':
      'To upload documents or view documents ${client} has given you access to, you must agree to the following:',
    'I will be able to access sensitive documents uploaded by clients I work with, and I agree to protect this information.':
      'I will be able to access sensitive documents uploaded by clients I work with, and I agree to protect this information.',
    'I will follow relevant Federal, State and local laws related to confidentiality and privacy.':
      'I will follow relevant Federal, State and local laws related to confidentiality and privacy.',
    'I will not disclose these documents in any way beyond the specific purpose they are shared with me.':
      'I will not disclose these documents in any way beyond the specific purpose they are shared with me.',
    'I accept that the owner of these documents can revoke access at any time.':
      'I accept that the owner of these documents can revoke access at any time.',
    'I will immediately delete any document that I upload for a client upon their request.':
      'I will immediately delete any document that I upload for a client upon their request.',
    'I will not accept documents shared by One Degree users I have no relationship with, and will remove my access to any I mistakenly accept.':
      'I will not accept documents shared by One Degree users I have no relationship with, and will remove my access to any I mistakenly accept.',
    'Type your name to accept these conditions': 'Type your name to accept these conditions',
    'Full name': 'Full name',
    Agree: 'Agree',
    'Select more documents': 'Select more documents',
    'Upload a different document': 'Upload a different document',
    'Finish upload': 'Finish upload',
    'Document details': 'Document details',
    'A document name is required': 'A document name is required',
    'Document name cannot be more than 225 characters': 'Document name cannot be more than 225 characters'
  }),
  es: Object.assign({}, ui.es, {
    'Edit document': 'Editar documento',
    'View document': 'Ver documento',
    'Document updated successfully.': 'Documento actualizado correctamente.',
    'Document update failed, please try again.': 'Error al actualizar el documento, inténtelo de nuevo.',
    'Upload settings': 'Configuración de carga',
    'Upload document': 'Subir documento',
    'Your documents are loading': 'Sus documentos se están cargando',
    'Document Name': 'Nombre del documento',
    'Document name': 'Nombre del documento',
    'Uploaded On': 'Subido en',
    'Uploaded By': 'Subido por',
    Actions: 'Acciones',
    'Your document will begin downloading to your device shortly.': 'Su documento comenzará a descargarse en su dispositivo en breve.',
    'Document successfully downloaded.': 'Documento descargado correctamente.',
    'Sorry! The download was unsuccessful.': '¡Lo sentimos! La descarga no se realizó correctamente.',
    'Want to keep track of your important documents in one place? Upload your documents and they’ll be safely stored and easily accessible later.':
      '¿Desea reunir sus documentos importantes en un solo lugar? Suba sus documentos y se guardarán de forma segura y podrá acceder a ellos fácilmente más tarde.',
    'Uploaded at by uploader': 'Subido en ${at} por ${uploader}',
    'Shared with count others': 'Compartido con ${count} personas',
    'Not shared': 'No compartido',
    'Document loading failed.': 'Error al cargar el documento.',
    'A document name is required': 'Se requiere un nombre del documento',
    Self: 'Usted',
    'Uploaded by': 'Subido por',
    'Shared with': 'Compartido con',
    'Shared With': 'Compartido Con',
    'No one has access yet': 'Nadie tiene acceso todavía',
    'To start using Documents, please verify your account with your email or cell phone number.':
      'Para usar <strong>Documentos</strong>, verifique su cuenta con su correo electrónico o número celular.',
    'Please email us at help@1degree.org or text us anytime at (800) 484-0126. We will respond within 1-3 business days.':
      'Envíenos un correo electrónico a <a href=‘mailto:help@1degree.org’>help@1degree.org</a> o envíenos un mensaje de texto al <a href=‘tel:+18004840126’>(800) 484-0126</a>.',
    'Your email': 'Su correo electrónico',
    'Your cell phone': 'su celular',
    Update: 'Actualizar',
    'file too large': 'El archivo es demasiado grande',
    'file too large message': 'Este archivo es demasiado grande. Los documentos deben tener menos de 5MB. Intente subir un documento diferente.',
    'file not supported': 'Tipo de archivo no compatible',
    'file not supported message':
      'Este tipo de archivo no es compatible. Los documentos solo pueden estar en formato jpeg, png o pdf. Intente subir un documento diferente.',
    'Send email verification': 'Enviar verificación de correo electrónico',
    'Send text verification': 'Enviar verificación de texto',
    'Email saved successfully.': 'Correo electrónico guardado con éxito.',
    'Cell phone saved successfully.': 'Celular guardado con éxito.',
    'Email verification sent!': '¡Verificación de correo electrónico enviada!',
    'Cell phone verification sent!': '¡Se envió la verificación del celular!',
    'Please login to view your documents.': 'Inicie sesión o registrarse para ver sus documentos.',
    "Upload important documents like your ID, utility bills, or Social Security card to easily access later. They'll be safely stored and kept private and you can control who you share them with.":
      '<strong>Cargue documentos importantes</strong> como su identificación, facturas de servicios públicos o tarjeta de seguro social para acceder fácilmente más tarde. <strong>Se guardaran de forma segura, se mantendrán privado</strong> y podrás <strong>controlar con quién los compartes</strong>.',
    "Upload important documents to easily access later. They'll be safely stored and kept private and you can control who you share them with.":
      '<strong>Cargue documentos importantes</strong> para acceder fácilmente más tarde. <strong>Se guardaran de forma segura, se mantendrán privado</strong> y podrás <strong>controlar con quién los compartes</strong>.',
    'Documents need to be a PDF, PNG, or JPG and less than 5MB in size.':
      'Los documentos deben ser PDF, PNG o JPG y tener un tamaño inferior de 5 MB.',
    'Upload a PDF, PNG, or JPG that is less than 5MB in size.': 'Cargue un PDF, PNG o JPG que tenga menos de 5 MB de tamaño.',
    "Client hasn't given you access to view any of their documents. But you can still upload documents to client's account.":
      '${client} no te ha dado acceso para ver ninguno de sus documentos. Pero aún puede cargar documentos en la cuenta de ${client}.',
    log_in_sign_up: 'Acceder/Registrarse',
    log_in: 'Acceder',
    'Have issues verifying your account?': '¿Tiene problemas para verificar su cuenta?',
    'Are you sure you want to remove your access to this document?': '¿Está seguro que desea eliminar su acceso a este documento?',
    'You will no longer be able to view this document. This can be helpful if a document was shared with you by mistake.':
      'Ya no podrá ver este documento. Esto puede ser útil si un documento se compartió con usted por error.',
    'Remove my access': 'Eliminar mi acceso',
    'Yes, remove access': 'Sí, quitar acceso',
    "No, don't remove access": 'No, no elimine el acceso',
    'Access successfully removed.': 'Acceso eliminado con éxito.',
    'Failed to remove access. Please try again.': 'No se pudo eliminar el acceso. Inténtalo de nuevo.',
    'Failed to be removed access. Please try again.': 'No se pudo eliminar el acceso. Inténtalo de nuevo.',
    'To upload documents or view documents has given you access to, you must agree to the following:':
      'Para subir documentos o ver documentos a los que ${client} le ha dado acceso, debe aceptar lo siguiente:',
    'I will be able to access sensitive documents uploaded by clients I work with, and I agree to protect this information.':
      'Podré acceder documentos confidenciales subido por clientes con los que trabajo y acepto proteger esta información.',
    'I will follow relevant Federal, State and local laws related to confidentiality and privacy.':
      'Seguiré las leyes federales, estatales y locales pertinentes relacionadas con la confidencialidad y la privacidad.',
    'I will not disclose these documents in any way beyond the specific purpose they are shared with me.':
      'No divulgaré estos documentos de ninguna manera más allá del propósito específico para el que se comparten conmigo.',
    'I accept that the owner of these documents can revoke access at any time.':
      'Acepto que el propietario de estos documentos pueda revocar el acceso en cualquier momento.',
    'I will immediately delete any document that I upload for a client upon their request.':
      'Si un cliente lo solicita, eliminaré inmediatamente cualquier documento que haya subido para ellos.',
    'I will not accept documents shared by One Degree users I have no relationship with, and will remove my access to any I mistakenly accept.':
      'No aceptaré documentos compartidos por usuarios de One Degree con los que no tengo ninguna relación, y eliminaré mi acceso a cualquiera que acepte por error.',
    'Type your name to accept these conditions': 'Escriba su nombre para aceptar estas condiciones',
    'Full name': 'Nombre completo',
    Agree: 'Aceptar',
    'Select more documents': 'Seleccione más documentos',
    'Upload a different document': 'Sube un documento diferente',
    'Finish upload': 'Finalizar carga',
    'Document details': 'Detalles del documento',
    'A document name is required': 'Es necesario que le dé un nombre al documento',
    'Document name cannot be more than 225 characters': 'El nombre del documento no puede tener más de 225 caracteres'
  })
};

export default withTranslations(translations);
