import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { locationHelpers } from '@/utils/helpers';
import useHoneybadger from '@/hooks/useHoneybadger';

/** Developer note: this context generally shouldn't be used directly.
 *** Instead the `useLocation` hook should always be used because the initial render `useEffect` needs
 *** to know about the `user` and our design of hooks resolves the potential
 *** for cyclical dependencies in contexts.
 *** For places where the `useLocation` hook cannot be used, it's recommended to call the initLocation
 *** method in a `useEffect` with no dependencies
 **/

const LocationContext = React.createContext({});

const LocationProvider = ({ children }) => {
  const [location, setLocation, resetLocation] = useLocalStorage('location', false);
  const [region, setRegion, resetRegion] = useLocalStorage('region', false);
  const [locationIsDefault, setLocationIsDefault, resetLocationIsDefault] = useLocalStorage('locationIsDefault', false);
  const [isDetectingLocation, setIsDetectingLocation] = useState(false);
  const { notifyHoneybadger } = useHoneybadger();

  const isLocationStale = () => {
    return location && (!location.cachedAt || location.cachedAt + locationHelpers.cacheExpiration < Date.now());
  };

  const initLocation = (account) => {
    if ((region === false || location === false) && !isDetectingLocation) {
      setIsDetectingLocation(true);
      locationHelpers.getCurrentRegion().then(({ region, usingDefaultRegion }) => {
        setRegion(region);
        setLocationIsDefault(usingDefaultRegion);

        // NOTE: Below is a divergence from the Angular handling of Location when only region is known.
        // Angular only sets the location based on region when it wasn't using the default region.
        // This change should be fine but consider this if unexpected behavior appears around whether or not we know the user's actual location.

        // set location based on region
        if (!location || (isLocationStale() && !account?.cachedUser?.is_professional)) {
          locationHelpers.checkGeolocationPermissions().then((permissionGranted) => {
            if (permissionGranted) {
              locationHelpers
                .getDetectedLocation()
                .then((detectedLocation) => {
                  setLocation(detectedLocation);
                  setIsDetectingLocation(false);
                })
                .catch((error) => {
                  setLocation(locationHelpers.getNamedLocation(region));
                  setIsDetectingLocation(false);
                });
            } else {
              setLocation(locationHelpers.getNamedLocation(region));
              setIsDetectingLocation(false);
            }
          });
        } else {
          setIsDetectingLocation(false);
        }
      });
    } else if (isLocationStale() && !account?.cachedUser?.is_professional && !isDetectingLocation) {
      setIsDetectingLocation(true);
      locationHelpers.checkGeolocationPermissions().then((permissionGranted) => {
        if (permissionGranted) {
          locationHelpers
            .getDetectedLocation()
            .then((detectedLocation) => {
              setLocation(detectedLocation);
              setIsDetectingLocation(false);
            })
            .catch((error) => {
              //do nothing on error - keep current location, but also don't throw an error.
              setIsDetectingLocation(false);
            });
        } else {
          setIsDetectingLocation(false);
        }
      });
    }
  };

  useEffect(() => {
    if (typeof location?.lat == 'undefined' || typeof location?.lng == 'undefined') {
      notifyHoneybadger('React location context lat lng is undefined', {
        message: 'React location context lat lng is undefined',
        component: 'Location.context',
        context: {
          location: location
        }
      });
    }
  }, [location]);

  const updateLocationByDetection = async () => {
    const detectedLocation = await locationHelpers.getDetectedLocation();
    const { region, usingDefaultRegion } = await locationHelpers.getRegionByLatLng(detectedLocation.lat, detectedLocation.lng);
    setRegion(region);
    setLocationIsDefault(usingDefaultRegion);
    setLocation(detectedLocation);
  };

  const updateLocationByLatLng = async (lat, lng) => {
    const { region, usingDefaultRegion } = await locationHelpers.getRegionByLatLng(lat, lng);
    setRegion(region);
    setLocationIsDefault(usingDefaultRegion);
    const newLocation = await locationHelpers.reverseGeocode(lat, lng);
    setLocation(newLocation);
  };

  const reverseGeocode = async (lat, lng) => {
    return locationHelpers.reverseGeocode(lat, lng);
  };

  const updateLocationByPlace = async (place) => {
    if (place?.address_components) {
      updateLocation(locationHelpers.buildLocationFromPlace(place));
    } else {
      // do nothing. this ensures we keep the currently stored location. future opportunity for a product decision around un-setting one's location.
    }
  };

  const updateLocation = async ({ cachedAt, county, lat, locationName, lng, state, region: passedRegion, city, zip }) => {
    const { region, usingDefaultRegion } = passedRegion
      ? { region: passedRegion, usingDefaultRegion: false }
      : await locationHelpers.getRegionByLatLng(lat, lng);
    setRegion(region);
    setLocationIsDefault(usingDefaultRegion);
    setLocation({ county, latitude: lat, lat, lng, locationName, longitude: lng, state, city, zip, cachedAt: cachedAt || Date.now() });
  };

  const getLocation = () => {
    return location || {};
  };

  const getRegion = () => {
    return region || {};
  };

  const locationKnown = () => {
    return typeof location.lat !== 'undefined' && typeof location.lng !== 'undefined';
  };

  return (
    <LocationContext.Provider
      value={{
        location: location,
        setLocation: setLocation,
        locationIsDefault: locationIsDefault,
        setLocationIsDefault: setLocationIsDefault,
        initLocation: initLocation,
        locationKnown: locationKnown,
        region: region,
        setRegion: setRegion,
        getLocation: getLocation,
        getRegion: getRegion,
        reverseGeocode: reverseGeocode,
        updateLocation: updateLocation,
        updateLocationByLatLng: updateLocationByLatLng,
        updateLocationByPlace: updateLocationByPlace,
        updateLocationByDetection: updateLocationByDetection
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
