import ODAS from '@/utils/api/odas';

const Account = {
  getUserInfo: (keys) => {
    const params = {};
    return ODAS.get('api/account', params);
  },
  getVaultId: () => {
    return ODAS.get('api/user/vault');
  },
  getUserSettings: () => {
    return ODAS.get('/api/account/settings');
  },
  bootstrapCLR: () => {
    return ODAS.get('/api/organizations/agency_referral_networks/bootstrap');
  },
  updateUserSettings: (params) => {
    return ODAS.put('/api/account/settings', { settings: params });
  },
  updateUserInfo: (params) => {
    return ODAS.put('/api/account', { user: params });
  },
  removeAffiliation: () => {
    return ODAS.delete('/api/affiliations');
  },
  deleteUser: (password) => {
    // const params = { password: encodeURIComponent(password) };
    return ODAS.delete(`/api/account?password=${encodeURIComponent(password)}`);
  },
  addAffiliation: (affiliationHash) => {
    return ODAS.put('/api/affiliations', { affiliation: affiliationHash });
  },
  getSAMLAffiliationOptions: () => {
    return ODAS.get('api/account/affiliation_suggestions');
  },
  getSAMLConfiguration: () => {
    return ODAS.get('/api/session/saml_config');
  },
  resetPassword: (email, mobile) => {
    const params = { email, mobile };
    return ODAS.post('/api/passwords', { ...params });
  }
};

export default Account;
