// IMPORTANT:
// - Wherever possible, use the bootstrap grid to create responsive pages.
// - When it it not possible, use these viewport vars to only render elements for current screen size
// - Because we usually have three mocks (mobile, tablet, and desktop),
// those three viewport sizes (isMobile, isTablet, isDesktop) should work for 99% of your needs.
// - Only use the size-specific variables (isXs, isSm, isMd, isLg, and isXl) when the device-sized variables won't work.

import React, { useState } from 'react';
import { layoutHelpers } from '@/utils/helpers';

export const viewportContext = React.createContext({});

export const ViewportProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false); // = bootstrap extra small and small
  const [isTablet, setIsTablet] = useState(false); // = bootsrap medium
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false); // = bootstrap extra small, small, and medium
  const [isDesktop, setIsDesktop] = useState(false); // = bootstrap large

  const [isXs, setIsXs] = useState(false); // = bootstrap extra small and below
  const [isXl, setIsXl] = useState(false); // = bootstrap extra large, extra extra large, and above

  // IMPORTANT: These variables have been deprecated and are being phased out.
  // Please use the variables above!
  const [unusedIsXSPhone, unusedSetIsXSPhone] = useState(false);
  const [unusedIsPhone, unusedSetIsPhone] = useState(false);
  const [unusedIsTablet, unusedSetIsTablet] = useState(false);
  const [unusedIsMobile, unusedSetIsMobile] = useState(false);
  const [unusedIsXSDesktop, unusedSetIsXSDesktop] = useState(false);
  const [unusedIsDesktop, unusedSetIsDesktop] = useState(false);
  const [unusedIsPhoneMenuOpen, unusedSetIsPhoneMenuOpen] = useState(false);

  const breakPoints = {
    isMobile: setIsMobile,
    isTablet: setIsTablet,
    isMobileOrTablet: setIsMobileOrTablet,
    isDesktop: setIsDesktop,
    isXs: setIsXs,
    isXl: setIsXl
  };

  const unusedBreakPoints = {
    // IMPORTANT: These breakpoints have been deprecated and are being phased out.
    // Please use the breakpoints above!
    unusedIsXSPhone: unusedSetIsXSPhone,
    unusedIsPhone: unusedSetIsPhone,
    unusedIsMobile: unusedSetIsMobile, // should be used only for tracking, not for styling
    unusedIsTablet: unusedSetIsTablet,
    unusedIsDesktop: unusedSetIsDesktop,
    unusedIsXSDesktop: unusedSetIsXSDesktop
  };

  const setBreakPoints = (keys) => {
    for (let bp in breakPoints) {
      if (keys.includes(bp)) {
        breakPoints[bp](true);
      } else {
        breakPoints[bp](false);
      }
    }
  };

  const unusedSetBreakPoints = (keys) => {
    for (let bp in unusedBreakPoints) {
      if (keys.includes(bp)) {
        unusedBreakPoints[bp](true);
      } else {
        unusedBreakPoints[bp](false);
      }
    }
  };

  const handleWindowResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= layoutHelpers.xsMax) {
      setBreakPoints(['isMobile', 'isMobileOrTablet', 'isXs']);
    } else if (windowWidth <= layoutHelpers.mobileMax) {
      setBreakPoints(['isMobile', 'isMobileOrTablet']);
    } else if (windowWidth >= layoutHelpers.tabletMin && windowWidth <= layoutHelpers.tabletMax) {
      setBreakPoints(['isTablet', 'isMobileOrTablet']);
    } else if (windowWidth >= layoutHelpers.desktopMin && windowWidth <= layoutHelpers.desktopMax) {
      setBreakPoints(['isDesktop']);
    } else if (windowWidth >= layoutHelpers.xlMin) {
      setBreakPoints(['isDesktop', 'isXl']);
    }

    // IMPORTANT: These breakpoints have been deprecated and are being phased out.
    // Please use the breakpoints above!
    if (windowWidth >= layoutHelpers.unusedXSPhoneBreakPoint && windowWidth < layoutHelpers.unusedPhoneBreakPoint) {
      unusedSetBreakPoints(['unusedIsXSPhone', 'unusedIsMobile']);
    } else if (windowWidth >= layoutHelpers.unusedPhoneBreakPoint && windowWidth < layoutHelpers.unusedMobileBreakPoint) {
      unusedSetBreakPoints(['unusedIsPhone', 'unusedIsMobile']);
    } else if (windowWidth >= layoutHelpers.unusedMobileBreakPoint && windowWidth < layoutHelpers.unusedTabletBreakPoint) {
      unusedSetBreakPoints(['unusedIsTablet']);
    } else if (windowWidth >= layoutHelpers.unusedTabletBreakPoint && windowWidth < layoutHelpers.unusedDeskTopBreakPoint) {
      unusedSetBreakPoints(['unusedIsXSDesktop']);
    } else if (windowWidth >= layoutHelpers.unusedDeskTopBreakPoint) {
      unusedSetBreakPoints(['unusedIsDesktop']);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  React.useEffect(() => {
    function handleClickOutside(event) {
      const mobileMenu = document.querySelector('#mobileMenuDrawer');

      if (unusedIsPhoneMenuOpen && mobileMenu && !mobileMenu.contains(event.target)) {
        unusedSetIsPhoneMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [unusedIsPhoneMenuOpen]);

  return (
    <viewportContext.Provider
      value={{
        isMobile,
        isTablet,
        isMobileOrTablet,
        isDesktop,
        isXs,
        isXl,
        unusedIsDesktop,
        unusedIsXSDesktop,
        unusedIsTablet,
        unusedIsPhone,
        unusedIsXSPhone,
        unusedIsMobile,
        unusedIsPhoneMenuOpen,
        unusedSetIsPhoneMenuOpen
      }}
    >
      {children}
    </viewportContext.Provider>
  );
};

export const ViewportConsumer = viewportContext.Consumer;

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
//const useViewport = () => {
/* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
//  const { width, height } = React.useContext(viewportContext);
//  return { width, height };
//}
