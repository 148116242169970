// https://github.com/js-cookie/js-cookie
// Cookies.get() // => { name: 'value' }
import Cookies from 'js-cookie';
import { isObject, isJson } from '@/utils/utils';

var getKey = function (key) {
  return 'one-degree_' + key;
};

export const setCookie = (key, value, expires) => {
  Cookies.set(getKey(key), isObject(value) ? JSON.stringify(value) : value, expires);
};

export const getCookie = (key) => {
  const value = Cookies.get(getKey(key));
  return isJson(value) ? JSON.parse(value) : value;
};

export const deleteCookie = (key) => {
  Cookies.remove(getKey(key));
};
