export default {
  mobileMax: 767, // = bootstrap small max
  tabletMin: 768, // = bootstrap medium min
  tabletMax: 991, // = bootstrap medium max
  desktopMin: 992, // = bootstrap large min
  desktopMax: 1199, // = bootstrap large max

  // Only use these when the device-sized variables won't work.
  xsMin: 320, // bootstrap extra small min
  xsMax: 575, // bootstrap extra small max
  smMin: 576, // bootstrap small min
  smMax: 767, // bootstrap small max
  mdMin: 768, // bootstrap medium min
  mdMax: 991, // bootstrap medium max
  lgMin: 992, // bootstrap large min
  lgMax: 1199, // bootstrap large max
  xlMin: 1200, // bootstrap extra large min
  xlMax: 1399, // bootstrap extra large max
  xxlMin: 1400, // bootstrap extra extra large min

  // IMPORTANT: These variables have been deprecated and are being phased out.
  // Please use the variables above!
  unusedXSPhoneBreakPoint: 320, // 320-574
  unusedPhoneBreakPoint: 575, // 575-992
  unusedMobileBreakPoint: 992, // matches angular
  unusedTabletBreakPoint: 1025, // this is expected to apply to most tablets in portrait mode
  unusedDeskTopBreakPoint: 1300
};
