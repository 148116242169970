import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import Auth from '@/utils/api/odas/auth';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import Access, { AccessEventStatuses } from '@/utils/api/odas/access';
import Account from '@/utils/api/odas/account';
import Bookmarks from '@/utils/api/odas/bookmarks';
import ODAS from '@/utils/api/odas';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import findIndex from 'lodash/findIndex';
import { accessEventHelpers, accountHelpers } from '@/utils/helpers';
import { getCookie } from '@/utils/cookies';
import ReferralNetwork from '@/utils/referral_network';
import useBootstrapCLR from '@/hooks/useBootstrapCLR';

const defaultAccessEventState = {
  accessed: [],
  planned: [],
  utilized: [],
  rejected: [],
  unsuccessful: [],
  clrStatus: []
};

const AccountContext = React.createContext([{}, () => {}]);

const AccountProvider = ({ children }) => {
  const [nextPage, setNextPage] = useState();
  const [nextPageTarget, setNextPageTarget] = useState();
  const [loginState, setLoginState] = useState({ isLoggedIn: false });
  const [accessEvents, setAccessEvents] = useState(defaultAccessEventState);
  const [bookmarks, setBookmarks] = useState({});
  const [vaultId, setVaultId] = useState();
  const [accountInfo, setAccountInfo] = useState({});
  const [settingsHash, setSettingsHash] = useState();
  const [user, setUser, resetUser] = useLocalStorage('user', {});
  const [affiliationInfo, setAffiliationInfo] = useState(''); // not reliable; only set from inside useAccount hook
  const [isSessionRetrieved, setIsSessionRetrieved] = useState(false);
  const [samlConfig, setSamlConfig] = useState(false);
  const [samlPartner, setSamlPartner] = useState(false);
  const { getCLRConfig, isLoadingClrData, bootstrapCLRData, partnerOrgs } = useBootstrapCLR();
  const loginProvider = 'one-degree'; // used for Mixpanel tracking
  const cachedUser = getCookie('user');

  // convenience attributes to check specific account and affiliation details
  const isLoggedIn = loginState.isLoggedIn === true;
  const isSessionExpired = !!Auth.sessionIsExpired;
  const hasActiveSession = isLoggedIn && !isSessionExpired;
  const orgNetwork = useMemo(() => ReferralNetwork.inspectAffiliation(accountInfo), [accountInfo]);
  const affiliation = accountInfo?.affiliation;
  const hasAffiliation = !!affiliationInfo || !!affiliation; // TODO consolidate, and check is_approved
  const isPro = !!accountInfo?.is_professional; // TODO combine with checking for affiliation?
  const isAdmin = affiliationInfo?.is_admin === true || affiliation?.is_admin === true;
  const proData = {
    isPro,
    isProOrgAdmin: isAdmin,
    hasAffiliation,
    hasPendingAffiliation: affiliationInfo?.is_approved === false || affiliation?.is_approved === false,
    isAffiliatedPro: isPro && hasAffiliation,
    orgNetwork
    // isActiveClrOrg: orgNetwork?.hasClr
  };

  const getAccessEvents = async () => {
    const resp = await Access.getAllAccessEvents('Opportunity', { attributes_to_include: 'locations,access_instructions,rating' });
    let accessEvents = {};

    accessEvents.accessed = filter(resp?.data, { status: 'accessed' });
    accessEvents.planned = filter(resp?.data, { status: 'planned' });
    accessEvents.utilized = filter(resp?.data, { status: 'utilized' });
    accessEvents.rejected = filter(resp?.data, { status: 'rejected' });
    accessEvents.unsuccessful = filter(resp?.data, { status: 'unsuccessful' });
    // Collect all of the CLR statuses together as they are geneally handled the same by the front end from the Community Member point-of-view
    accessEvents.clrStatus = filter(
      resp?.data,
      (ae) => ['sent', 'acknowledged', 'assigned', 'reassigned', 'reminder', 'in_progress'].indexOf(ae.status) >= 0
    );
    accessEvents.hasAccessEvents =
      flatMap(accessEvents, (value, key, collection) => {
        return value;
      }).length > 0;
    setAccessEvents(accessEvents);
  };

  // get bookmarks collection
  const getBookmarks = async () => {
    const resp = await Bookmarks.getAllBookmarks({});
    setBookmarks({
      bookmarks: resp?.data?.bookmarks,
      // pre-filter a collection of Organization bookmarks for convenience when working with a the user's Plan
      planBookmarks: resp?.data?.bookmarks.filter((bookmark) => bookmark.fetchable_type === 'Organization')
    });
  };

  const getUserSettings = async () => {
    const resp = await Account.getUserSettings();
    setSettingsHash(resp?.data?.settings);
  };

  const getSamlConfig = () => {
    if (samlConfig === false) {
      return accountHelpers.fetchSamlConfig().then((config) => {
        setSamlConfig(config);
      });
    } else {
      return samlConfig;
    }
  };

  const fetchBookmarksAndAccessEvents = () => {
    if (isLoggedIn) {
      setAccessEvents(defaultAccessEventState);
      setBookmarks({});
      getAccessEvents();
      getBookmarks();
    }
  };

  // Account data is saved in three locations so ensure they all remain consistent:
  // 1. Memory (i.e. this Context's accountInfo state)
  // 2. Cookie
  // 3. LocalStorage (i.e. this Context's user state)
  // TODO: Consider if 1 and 3 can be combined
  const storeUserInfo = (user) => {
    setUser(JSON.stringify(user));
    setAccountInfo(user);
    Auth.saveUserCookie(user);
  };

  const getUserInfo = async () => {
    const resp = await Account.getUserInfo();
    storeUserInfo(resp?.data?.user);
    setIsSessionRetrieved(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      getAccessEvents();
      getBookmarks();
      getUserSettings();
      getUserInfo();
      getCLRConfig();
    } else {
      // TODO clear data when not logged in?
      setIsSessionRetrieved(true);
    }
  }, [isLoggedIn]);

  const getVaultId = async () => {
    const resp = await Account.getVaultId();
    setVaultId(resp?.data?.vault_id);
  };

  useEffect(() => {
    if (Auth.isLoggedIn()) {
      setLoginState({ isLoggedIn: true });
    }
  }, []);

  const updateAccountInfo = async (data) => {
    const accountData = await ODAS.put('/api/user', data, { credentials: 'omit' });
    if (accountData?.response?.user) {
      storeUserInfo(accountData?.response?.user);
      return accountData.response;
    } else {
      return accountData?.response;
    }
  };

  const createOrUpdateAffiliation = async (data) => {
    try {
      const resp = await ODAS.put('/api/affiliations', data);
      return resp?.response;
    } catch (e) {
      console.warn(e);
    }
  };

  const updateBookmarkCollection = ({ action, bookmark }) => {
    switch (action) {
      case 'add':
        setBookmarks((prevState) => {
          Object.keys(prevState).forEach((key) => {
            let foundBookmark = findIndex(prevState[key], { fetchable_type: bookmark.fetchable_type, fetchable_id: bookmark.fetchable_id });
            if (foundBookmark === -1) {
              prevState[key] = [...prevState[key], bookmark];
            }
          });
          return { ...prevState };
        });
        break;
      case 'remove':
        setBookmarks((prevState) => {
          Object.keys(prevState).forEach((key) => {
            let foundBookmark = findIndex(prevState[key], { fetchable_type: bookmark.fetchable_type, fetchable_id: bookmark.fetchable_id });
            if (foundBookmark >= 0) {
              prevState[key].splice(foundBookmark, 1);
              prevState[key] = [...prevState[key]];
            }
          });
          return { ...prevState };
        });
        break;
    }
  };

  const updateAccessEventCollection = ({ action, accessEvent }) => {
    let aeGroup = accessEventHelpers.getGroupFromStatus(accessEvent.status);
    switch (action) {
      case 'add':
        setAccessEvents((prevState) => {
          let foundAccessEvent = findIndex(prevState[aeGroup], { id: accessEvent.id });
          if (foundAccessEvent === -1) {
            prevState[aeGroup] = [accessEvent, ...prevState[aeGroup]];
          }
          return { ...prevState };
        });
        break;
      case 'remove':
        setAccessEvents((prevState) => {
          let foundAccessEvent = findIndex(prevState[aeGroup], { id: accessEvent.id });
          if (foundAccessEvent >= 0) {
            prevState[aeGroup].splice(foundAccessEvent, 1);
            prevState[aeGroup] = [...prevState[aeGroup]];
          }
          return { ...prevState };
        });
        break;
      case 'statusChange':
        setAccessEvents((prevState) => {
          Object.keys(prevState).forEach((key) => {
            let foundAccessEvent = findIndex(prevState[key], { id: accessEvent.id });
            if (foundAccessEvent >= 0 && aeGroup !== key) {
              prevState[key].splice(foundAccessEvent, 1);
              prevState[key] = [...prevState[key]];
            } else if (foundAccessEvent === -1 && aeGroup === key) {
              prevState[key] = [accessEvent, ...prevState[key]];
            }
          });
          return { ...prevState };
        });
        break;
    }
  };

  const trackUser = () => {
    if (typeof accountInfo?.track != 'undefined') {
      return accountInfo.track;
    }
    // Upon page load, account data may not be loaded, so check cookies
    if (typeof cachedUser?.track != 'undefined') {
      return cachedUser.track;
    }
    return true;
  };

  return (
    <AccountContext.Provider
      value={{
        proData,
        isPro,
        isSessionRetrieved,
        isLoggedIn,
        isSessionExpired,
        hasActiveSession,
        state: loginState,
        setState: setLoginState,
        nextPage,
        setNextPage,
        nextPageTarget,
        setNextPageTarget,
        data: accountInfo,
        bookmarks: bookmarks,
        setBookmarks: setBookmarks,
        updateBookmarkCollection,
        updateAccessEventCollection,
        accessEvents: accessEvents,
        setAccessEvents: setAccessEvents,
        storeUserInfo,
        trackUser: trackUser,
        createOrUpdateAffiliation,
        affiliation: affiliationInfo, // TODO consolidate with accountInfo's affiliation object
        setAffiliationInfo: setAffiliationInfo,
        updateAccountInfo: updateAccountInfo,
        getUserInfo: getUserInfo,
        getVaultId: getVaultId,
        settingsHash: settingsHash,
        vaultId: vaultId,
        fetchBookmarksAndAccessEvents: fetchBookmarksAndAccessEvents,
        cachedUser,
        partnerOrgs,
        bootstrapCLRData,
        isLoadingClrData,
        samlConfig,
        getSamlConfig,
        samlPartner,
        setSamlPartner
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };
