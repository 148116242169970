import referralNetworkList from '@/rails/config/properties/referral_networks.yml';
import Auth from '@/utils/api/odas/auth';
import { filter, map, flatten, isEmpty, clone, each, keys, values, find, extend } from 'lodash';

// const PARTNER_NETWORK_ID = 0;

const REFERRAL_NETWORKS = {};
// REFERRAL_NETWORKS['' + PARTNER_NETWORK_ID] = getPartnerNetwork();

const REL = {
  // relationships
  owner: 'owner',
  child: 'child',
  cbo: 'cbo'
};

const CLR_OPTIONS = {};
// CLR_OPTIONS['' + PARTNER_NETWORK_ID] = {};

const splitFinalToken = (str, separator) => {
  const sep = separator || ':';
  const index = str.lastIndexOf(sep);
  if (index == -1) return [str];
  const tokens = (str || '').split(sep);
  const lastToken = tokens[tokens.length - 1];
  const otherTokens = str.substring(0, index);
  return map(lastToken.split(','), (t) => otherTokens + sep + t);
};

each(referralNetworkList, (network) => {
  network.id = network.id.toString();
  REFERRAL_NETWORKS[network.id] = network;
  CLR_OPTIONS[network.id] = {};
  each(network.clr_options || [], function (flag) {
    each(splitFinalToken(flag), function (f) {
      CLR_OPTIONS[network.id][f] = true;
    });
  });
});

const matchCLROption = (networkId, regex, index) => {
  index = index || 0; // allows for capture groups, or returns the whole string by default
  if (!networkId || !CLR_OPTIONS[networkId]) return false;
  let found = null;
  find(CLR_OPTIONS[networkId], (ignore, flag) => {
    let m = flag.match(regex);
    if (m && m.length > index) {
      found = m[index];
      return true; // break
    }
  });
  return found;
};

function getReferralNetworkById(id) {
  return REFERRAL_NETWORKS[id];
}

// // placeholder for the concept of a partner network (vs a formal CLR network)
// function getPartnerNetwork() {
//   return {
//     id: PARTNER_NETWORK_ID,
//     name: 'My Partner Network',
//     icon: 'my-partner-network',
//     slug: 'my-partner-network',
//     long_name: 'My Partner Network',
//     short_name: 'My Partner Network',
//     clr_options: ['']
//   };
// }

// references to translation keys for messages to display on the frontend to explain
// why a referral is being disallowed
const RESTRICTION_I18N_KEYS = {
  cannot_process_referral_to_another_orgname: 'One Degree cannot process a patient referral to another orgName site or program.'
};

const ReferralNetwork = {
  getReferralNetworks: () => {
    return REFERRAL_NETWORKS;
  },
  referrals: {
    // functions should return a translation key if a referral from `arnFrom` to `arnTo` should NOT be allowed
    disallow: {
      // true if both referring org and referred org are either owner/child of this network
      among_owner_and_child_orgs: function (arnFrom, arnTo) {
        const disallowed =
          arnFrom.referral_network_id === arnTo.referral_network_id &&
          (arnFrom.relationship === 'owner' || arnFrom.relationship === 'child') &&
          (arnTo.relationship === 'owner' || arnTo.relationship === 'child');
        return disallowed ? RESTRICTION_I18N_KEYS.cannot_process_referral_to_another_orgname : false;
      }
    }
  },

  getReferralNetworkById,
  // getPartnerNetwork,

  getReferralNetworkBySlug: (slug) => {
    let network = filter(REFERRAL_NETWORKS, (network) => {
      return network.slug == slug;
    });

    return network[0];
  },

  getReferralNetworkByName: (name) => {
    let network = filter(REFERRAL_NETWORKS, (network) => {
      return network.name == name;
    });

    return network[0];
  },

  needsUpdate: (user, orgNetwork) => {
    if (user && (!orgNetwork || !orgNetwork.user || orgNetwork.user.id !== user.id)) {
      return true;
    }
    if (!user && orgNetwork && orgNetwork.user) {
      return true;
    }
    return false;
  },
  // find exact (bool) or prefixed matches (array) for `key` in clr_options for a given network
  //
  // - opts.prefix = true: find matches that begin with `key`
  //   ex. if network had clr_options = ['foo:111', 'bar:222', 'foo:goo:true', 'baz:foo:test']
  //   findCLROption(network, 'foo:', {prefix: true}) => ['111', 'goo:true']
  findCLROption: (network, key, opts) => {
    opts = opts || {};
    let val = opts.prefix ? [] : false;
    if (network && network.clr_options) {
      each(network.clr_options, function (flag) {
        if (opts.prefix && flag.indexOf(key) === 0) {
          val.push(flag.substring(opts.prefix.length));
        } else if (flag === key) {
          val = true;
        }
      });
    }
    return val;
  },
  referralRetrictionRules: (networks) => {
    const rules = {};
    map(networks, (network, id) => {
      const restrictions = ReferralNetwork.findCLROption(network.network_config, 'referrals:disallow:', {
        prefix: true
      });
      rules[id] = [];
      map(restrictions, (key) => {
        const k = key.replace('referrals:disallow:', '');
        rules[id].push(ReferralNetwork.referrals.disallow[k]);
      });
    });

    return rules;
  },
  /**
   * Check for any opportunities that should not be included in a referral.
   *
   * CLR allows networks to configure referrals to restrict opportunities from certain orgs
   * in the network. This will return a list of opportunities that should be removed from
   * the referral, plus a list of network owner organization names (to show in the modal).
   */
  getRestrictedReferralOpps: (orgNetwork, cart, networkMap) => {
    if (!networkMap) return false;
    const restrictionMap = orgNetwork ? ReferralNetwork.referralRetrictionRules(networkMap) : null;
    if (isEmpty(restrictionMap)) {
      return false; // no network, or no referral restrictions on any network
    }
    const restrictedOrgIds = {}; // restrictions are determined by the org and network ids. TODO restrict org in one network but not another
    const networkOwnerNames = {};
    const oppNetworkMap = {};
    const opps = flatten([cart.insideNetwork || [], cart.outsideNetwork || []]);
    const involvedOrgs = {};
    each(opps, ({ opportunity: opp }) => {
      involvedOrgs[opp.organization.id] ||= [];
      involvedOrgs[opp.organization.id].push(opp.id);
    });
    // iterate over the networks I'm in
    each(networkMap, (networkData, networkId) => {
      const networkName = networkData.network_config.name;
      const mySummary = networkData.my_agency_summary; // my org's relationship within this network
      const restrictions = restrictionMap[networkData.network_id] || [];
      // skip if this CLR network does not have any restriction functions defined
      if (!restrictions || restrictions.length === 0) {
        return; // continue
      }
      // iterate over the orgs in this network (their AgencyReferralNetwork object for this network)
      each(networkData.in_network_agencies, (arnSummary) => {
        const orgId = arnSummary.fetchable_id;
        // skip if this CLR network org is not receiving any of the referrals
        if (!involvedOrgs[orgId]) return; // continue
        // loop through the restrictions defined for this network, and see if a referral made to this
        // org should be prevented from happening
        let disallowed = false;
        each(restrictions, (disallow) => {
          if (disallowed) return; // continue
          const restrictionTranslationKey = disallow(mySummary, arnSummary);
          if (restrictionTranslationKey) {
            oppNetworkMap[orgId] = { orgId, networkName, networkId };
            restrictedOrgIds[orgId] ||= [];
            restrictedOrgIds[orgId].push({
              networkId,
              ownerOrgName: networkData.network_owner?.name || '', // fail gracefully if there is no owner (network removed?)
              networkName,
              restrictionTranslationKey
            });
            if (networkData.network_owner?.name) {
              networkOwnerNames[networkId] ||= networkData.network_owner.name;
              oppNetworkMap[orgId].networkOwnerName = networkData.network_owner.name;
            }
            disallowed = true;
          }
        });
      });
    });

    return {
      opps: filter(opps, ({ opportunity }) => !!restrictedOrgIds[opportunity.organization.id]),
      ownerNames: networkOwnerNames, // TODO needed? probably more useful in oppNetworkMap
      restrictedOrgIds,
      networks: oppNetworkMap
    };
  },
  hasCLROption: (networkId, flag) => {
    return networkId && CLR_OPTIONS[networkId] && CLR_OPTIONS[networkId][flag];
  },
  // TODO lots of these functions can be "private" - not exposed on the exported object - since they are only used in this file
  wrapAPI: (config) => {
    const idMap = {};
    each(config.networks, function (network) {
      idMap['' + network.id] = extend({}, network, REFERRAL_NETWORKS['' + network.id]);
    });
    const apiAny = ReferralNetwork.anyAPI(config, idMap);
    return extend({ any: apiAny }, ReferralNetwork.idAPI(config, idMap, apiAny), { clrUI: ReferralNetwork.uiAPI(config) }, config);
  },
  uiAPI: (config) => {
    // opts.relationship: if provided, ensure that my relationship matches the given value
    const check = (action, key, opts) => {
      return !!find(config.networks, (n) => {
        if (opts.relationship && n.relationship !== opts.relationship) return false; // skip if the relationship option does not match
        return (
          ReferralNetwork.hasCLROption(n.id, ['ui', action, key, n.relationship].join(':')) ||
          ReferralNetwork.hasCLROption(n.id, ['ui', action, key, 'all'].join(':'))
        );
      });
    };
    ////
    // show() - find a `clr_options` flag declaring that an element should be shown in the UI.
    // hide() - find a `clr_options` flag declaring that an element should not be shown in the UI.
    // require() - should an element be required, e.g. a form field that needs to be filled in by the user.
    // @param key: token from clr_options to search for (ex. show('foo:bar') to search for 'ui:show:foo:bar:<rel>')
    // @param opts (optional):
    //   - relationship: require my org to have this relationship (ex. 'owner' requires the org to also be the network owner)
    const api = {
      show: (key, opts) => check('show', key, opts || {}),
      hide: (key, opts) => check('hide', key, opts || {}),
      require: (key, opts) => check('require', key, opts || {})
    };
    return api;
  },
  idAPI: (config, idMap, apiAny) => {
    // @param id - the referral network id
    const api = {
      network: (id) => {
        return idMap['' + id] || {};
      },
      relationship: (id) => {
        return api.network(id).relationship;
      },
      isOwner: (id) => {
        return api.relationship(id) === REL.owner;
      },
      isOwnerOrChild: (id) => {
        return api.relationship(id) === REL.owner || api.relationship(id) === REL.child;
      },
      slug: (id) => {
        return api.network(id).slug || '';
      },
      name: (id) => {
        return api.network(id).name || '';
      },
      first: () => {
        return api.network(_.keys(idMap)[0]);
      },
      // TODO this logic is problematic if an org is the owner of more than one network
      firstOwner: () => {
        const owner = apiAny.listMyOwnedNetworks()[0] || {};
        return {
          data: owner,
          hasCLROption: function (key) {
            return ReferralNetwork.hasCLROption(owner.id, key);
          }
        };
      }
    };
    return api;
  },
  // api for logic that wants to know something that applies to any referral network
  // the current user belongs to (i.e. isn't a question specific to one network)
  anyAPI: (config, idMap) => {
    const any = {
      networks: () => {
        // get the basic config + relationship of all the networks I'm a member of
        return values(idMap);
      },
      ids: () => {
        return keys(idMap);
      },
      each: (predicate) => {
        return each(any.networks(), predicate);
      },
      map: (predicate) => {
        return map(any.networks(), predicate);
      },
      isCboAdmin: () => {
        return config.admin && any.isCBO();
      },
      isCBO: () => {
        return !!find(config.networks, (arn) => {
          return arn.relationship === REL.cbo;
        });
      },
      isOwner: () => {
        return !!find(config.networks, (arn) => {
          return arn.relationship === REL.owner;
        });
      },
      isOwnerOrChild: () => {
        return !!find(config.networks, (arn) => {
          return arn.relationship === REL.owner || arn.relationship === REL.child;
        });
      },
      canUpdateAgencyConfig: () => {
        return any.isCboAdmin(); // TODO will this logic make sense for any network? or is it specific to LA ACEs
      },
      slugs: () => {
        return map(config.networks, 'slug').join(' '); // all slugs separated by spaces
      },
      referralSlugs: (referral) => {
        return any.networkForReferral(referral).slug || '';
      },
      networkForReferral: (referral) => {
        const networkId = referral.referral_origin ? referral.referral_origin.referral_network_id : null;
        return networkId ? idMap[networkId] || {} : {};
      },
      /* @return object: {network.id => <list of restrictions>} */
      getReferralRestrictions: () => {
        const restrictionMap = {};
        any.each((network) => {
          const fns = referralRestrictionFunctions(network);
          if (fns.length) restrictionMap[network.id] = fns;
        });
        return restrictionMap;
      },
      allNames: () => {
        return any.map((network) => network.name);
      },
      hasCLROption: (key) => {
        return !!find(any.ids(), (id) => {
          return ReferralNetwork.hasCLROption(id, key);
        });
      },
      listMyOwnedNetworks: () => {
        return any.listForRelationship(REL.owner);
      },
      listForRelationship: (relationship) => {
        return filter(any.networks(), (arn) => {
          return arn.relationship === relationship;
        });
      },
      isValidNetworkId: (networkId) => {
        // if (networkId === PARTNER_NETWORK_ID) return config.partners.length > 0; // has partner orgs
        return !!idMap[networkId];
      }
    };
    return any;
  },
  /**
   * Get information about this user's affiliation with org networks.
   *
   * @returns object:
   * - admin (boolean) the user is an admin in their org
   * - isClr (boolean) the user is affiliated with an org that is within a referral network
   * - network (object) the referral network for this user, if any
   */
  inspectAffiliation: (user) => {
    user = user || Auth.getCachedUser();

    const data = {
      user: clone(user), // 7/21 - use this to in/validate the cached local var object
      admin: false,
      isClr: false, // TODO replace with hasClr, which is a flag of whether to show CLR UI (TODO what about closed-loop referrals for orgs no longer in any CLR?)
      // hasClr: false, // either in any referral network or has at least one partner org
      // partners: [], // partner org ids
      networks: [] // array of objects with {id (of network), name (of network), relationship (of org within the network)}
    };

    if (!user || !user.affiliation || !user.affiliation.is_approved || !user.affiliation.userActive) {
      return ReferralNetwork.wrapAPI(data);
    }

    data.admin = !!user.affiliation.is_admin;

    let networks = [];

    if (user.affiliation.organizationReferralNetworks) {
      networks = clone(user.affiliation.organizationReferralNetworks);
    }

    // if (user.affiliation.organizationPartnerOrgIds) {
    //   data.partners = clone(user.affiliation.organizationPartnerOrgIds);
    //   if (data.partners.length) {
    //     data.hasClr = true;
    //     networks.push(getPartnerNetwork());
    //   }
    // }

    if (!networks || networks.length === 0) {
      return ReferralNetwork.wrapAPI(data); // the affiliated org is not within a referral network
    }

    networks.sort((a, b) => a.id - b.id);

    // data.hasClr = true;
    data.isClr = true;
    data.networks = networks.map((network) => {
      const config = getReferralNetworkById(network.id);
      network.icon = config.icon;
      return network;
    });

    return ReferralNetwork.wrapAPI(data);
  },

  /**
   * Determine whether a resource (organization or opportunity) belongs to a list
   * of organizations. (Does not enforce network membership)
   *
   * @param resource - either an organization or opportunity object
   * @param networkOrgs - array of organization objects; or array of org ids; or map of org ids (see opts)
   * @param opts - (optional)
   *   - byId: if true, networkOrgs is an array of ids
   *   - byMap: if true, networkOrgs is a hash of ids
   * @return bool - whether the resource is in the given network of organizations.
   */
  isResourceInNetwork: (resource, networkOrgs, opts) => {
    opts = opts || {};
    if (!resource || !networkOrgs) return false;

    let compareId = null;
    if (resource.resource_type === 'Opportunity' && resource.organization) {
      compareId = resource.organization.id;
    } else if (resource.resource_type === 'Organization') {
      compareId = resource.id;
    } else return false;

    if (opts.byMap) {
      return !!networkOrgs[compareId];
    } else {
      return !!find(networkOrgs, (val) => (opts.byId ? val : val.fetchable_id) === compareId);
    }
  },

  /**
   * Redirect to a path determined by the user's referral network membership. This will look in the user's
   * list of CLR networks for a value in clr_options based on the given key.
   *
   * @param {string} key - a token defining the context of this redirect, e.g. to start a new referral
   * @param {object} opts (optional)
   *   - data - any data needed to generate the final redirect path
   *   - defaultPath - a path to use if there is no redir definition for the given key in this network
   */
  navigateForKey: (key, opts) => {
    opts = opts || {};
    let path = null;
    let paths = {
      // the keys here are defined at a part of clr_options, as custom redirects for orgs in referral networks
      'org-page-collections-view': () => '/org/' + opts.data.slug + '#collections', //THIS is never used anymore, per issue 10386
      'collections-page-org-view': () => '/collections?page=org&organization_id=' + opts.data.slug
    };
    try {
      const orgNetwork = ReferralNetwork.inspectAffiliation();
      let pathKey = null;
      orgNetwork.any.each((n) => {
        pathKey =
          pathKey ||
          matchCLROption(n.id, new RegExp('^redirect:' + key + ':(.+):' + n.relationship + '$'), 1) ||
          matchCLROption(n.id, new RegExp('^redirect:' + key + ':(.+):all$'), 1);
      });
      if (pathKey && typeof paths[pathKey] === 'function') {
        path = paths[pathKey]();
      }
    } catch (ex) {
      // ignore so we can at least use the default path if there was missing data
      console.error(ex);
    }
    // TODO: update this to use NextJS navigation once all pages are migrated to React
    window.location.href = path || opts.defaultPath || '/search';
  }
};

export default ReferralNetwork;
