import ratingList from '@/rails/config/properties/rating_types.yml';

const ratingHelpers = {
  EMPTY_RATING: 0,
  MAX_RATING: 5,
  getRatingTags: () => {
    return ratingList.rating_tags;
  },
  getRatingTag: (key) => {
    const ratingTags = ratingList.rating_tags;
    if (ratingTags && ratingTags[key]) {
      return ratingTags[key];
    }
  }
};

export default ratingHelpers;
