import find from 'lodash/find';

const organizationHelpers = {
  getOrgPageTabs: ({ isPro, isProOrgAdmin, isClr, showFacilitiesTab, hasAffiliation, hasPendingAffiliation, t, tabKey, organization }) => {
    const emptyTab = [
      {
        hidden: true
      }
    ];

    let tabs = [
      ...(hasAffiliation && isPro
        ? [
            {
              title: t('Organization page'),
              key: 'organization_page',
              redirect: `/org/${organization?.slug}`
            }
          ]
        : []),

      ...(isProOrgAdmin
        ? [
            {
              title: t(isClr ? 'Staff' : 'Users'),
              key: 'users',
              redirect: `/org/${organization?.slug}/${isClr ? 'staff' : 'users'}`
            }
          ]
        : []),

      ...(hasAffiliation && isPro
        ? [
            {
              title: t('Referrals'),
              key: 'referrals',
              redirect: `/org/${organization?.slug}/referrals`
            },
            {
              title: t('Clients'),
              key: 'clients',
              redirect: `/org/${organization?.slug}/clients`
            }
          ]
        : []),

      ...(showFacilitiesTab()
        ? [
            {
              title: t('Facilities'),
              key: 'facilities',
              redirect: `/org/${organization?.slug}/facilities`
            }
          ]
        : []),

      ...(isProOrgAdmin
        ? [
            {
              title: t('Reports'),
              key: 'reports'
            },
            {
              title: t('Settings'),
              key: 'settings',
              redirect: `/org/${organization?.slug}/settings`
            }
          ]
        : [])
    ];

    tabs = isPro && (!hasAffiliation || hasPendingAffiliation) ? emptyTab : tabs;
    let found_tab = false;

    if (tabKey) {
      found_tab = find(tabs, (tab) => {
        return tab.key == tabKey;
      });
    }
    return { tabs, activeTab: found_tab || tabs[0] };
  }
};

export default organizationHelpers;
