'use client';
// Custom app file
// This is the wrapper component that will wrap every page of the application
// https://nextjs.org/docs/advanced-features/custom-app
import React, { useEffect } from 'react';
import reportWebVitals from '@/reportWebVitals';
import Head from 'next/head';
import FavIcon from '@/rails/assets/images/favicon.png';
import Honeybadger from '@honeybadger-io/js';
import Language from '@/utils/Language';
import useMixpanel from '@/hooks/useMixpanel';
import { useRouter } from 'next/router';
import { getUrlParameter } from '@/utils/utils';
import { localStorageService } from '@/utils/local_storage';
import '@/assets/stylesheets/components/skeleton.scss';
import '@/assets/stylesheets/layout/_global.scss';

function MyApp({ Component, pageProps }) {
  const { track } = useMixpanel();
  const router = useRouter();

  useEffect(() => {
    const browserLang = getUrlParameter('locale') || localStorageService.get('locale') || navigator.language || navigator.userLanguage;
    const supportedLocales = ['en', 'es'];
    let detectedLocale = 'en';
    if (supportedLocales.includes(browserLang)) {
      detectedLocale = browserLang;
    } else {
      // If the full language tag is not supported, check the base language (e.g., 'es').
      const baseLanguage = browserLang.split('-')[0];
      if (supportedLocales.includes(baseLanguage)) {
        detectedLocale = baseLanguage;
      }
    }
    Language.setCurrentLanguage(detectedLocale);
    track('user session');
  }, []);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // TODO: check if this is still relevant
  reportWebVitals();

  const hbConfig = {
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.NODE_ENV
    // reportData: true // uncomment to enable reporting for dev environment
  };

  // On dev, we don't want to call Honeybadger.configure because it overrides console logging to reference HB source instead of our own source
  // Ref: https://github.com/honeybadger-io/honeybadger-js/issues/397#issuecomment-665284205
  if (process.env.NODE_ENV !== 'development' || hbConfig.reportData) {
    Honeybadger?.configure(hbConfig);
  }

  const getLayout = Component.getLayout || ((page) => page);

  // move the provider to a layout
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="shortcut icon" href={FavIcon?.src || '/favicon.png'} type="image/png" />
        {<title>{pageProps.pageTitle || 'One Degree'}</title>}
      </Head>
      {getLayout(<Component {...pageProps} />)}
    </>
  );
}

export default MyApp;
