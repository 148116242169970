import t from '@/utils/Language/Documents-translations';

const uploadedDocumentHelpers = {
  validateDocumentName: (name) => {
    if (!name || name?.trim()?.length === 0) {
      return { error: true, message: t('A document name is required') };
    }

    if (name?.trim()?.length > 225) {
      return { error: true, message: t('Document name cannot be more than 225 characters') };
    }

    return { error: false, message: null };
  },
  getFileName: (fileName, selectedFileType) => {
    const extName = selectedFileType.split('/')[1];
    const nameWithExt = fileName.split('.');
    if (nameWithExt[nameWithExt.length - 1] === 'jpg' || nameWithExt[nameWithExt.length - 1] === extName) {
      // assuming extension is already there
      return fileName;
    } else {
      return fileName + '.' + extName; // appending extension from fileType
    }
  }
};

export default uploadedDocumentHelpers;
