import API from '../index';
import hash from 'object-hash';
import Language from '@/utils/Language';
import { resourceHelpers } from '@/utils/helpers';

const ODRS = {};
const pendingRequests = {};
const baseURL = process.env.ODRS_URL || 'http://localhost:3002/';
const api_key = process.env.ODRS_API_KEY;

ODRS.search = async (resourceType, params = {}) => {
  const url = '/v1/' + resourceType;
  // digest the request into a unique string
  const requestHash = url + hash(params) + (params?.locale || Language.getCurrentLanguage());

  // check if there is a pending request for this exact request, if so, return that
  if (typeof pendingRequests[requestHash] !== 'undefined') {
    return await pendingRequests[requestHash];
  } else {
    // otherwise, make a fresh request and track it in the pending request object until it completes
    pendingRequests[requestHash] = API.get(url, params, { api_key, baseURL }).then((response) => {
      delete pendingRequests[requestHash];
      if (response?.data?.opportunities?.length) {
        response.data.opportunities = resourceHelpers.replaceAllWithTranslations(response.data.opportunities);
      }
      return response;
    });
    return await pendingRequests[requestHash];
  }
};

ODRS.getRelatedOpportunities = async (opportunityId, params = {}) => {
  params.titles_only = true;
  params.per_page = params.per_page || 3;
  const url = '/v1/opportunities/' + opportunityId + '/related';
  return API.get(url, params, { api_key, baseURL }).then((response) => {
    if (response?.data?.opportunities?.length) {
      response.data.opportunities = resourceHelpers.replaceAllWithTranslations(response.data.opportunities);
    }
    return response;
  });
};

ODRS.getResourcesByPartialName = async (resourceType, text, params = {}) => {
  const url = '/v1/' + resourceType;
  params['query[text]'] = text;
  return API.get(url, params, { api_key, baseURL });
};

ODRS.getResourceById = async (resourceType, id, params = {}) => {
  const url = '/v1/' + resourceType + '/' + id;
  return API.get(url, params, { api_key, baseURL });
};

ODRS.getResourcesById = async (resourceType, ids, params = {}) => {
  const url = '/v1/' + resourceType;
  params.ids = ids.join(',');
  return API.get(url, params, { api_key, baseURL });
};

ODRS.getTagTree = async (params = {}) => {
  const url = '/v1/tags/tree/public';
  return API.get(url, params, { api_key, baseURL });
};

ODRS.getResourceTags = async (params = {}) => {
  const url = '/v1/counts/tags';
  return API.get(url, params, { api_key, baseURL }).then((response) => {
    return response;
  });
};

ODRS.getResourceOpportunities = async (orgId, params = {}) => {
  const url = '/v1/organizations/' + orgId + '/opportunities';
  return API.get(url, params, { api_key, baseURL });
};

export default ODRS;
