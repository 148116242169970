import ODAS from '@/utils/api/odas';
import { deleteCookie, setCookie, getCookie } from '@/utils/cookies';
import { getUrlEncodedPath } from '@/utils/utils';

/**
 * A module that holds methods to save the user information after login or signup
 * and remove it after logout, including some private methods to retrieve cookies
 */
const Auth = {};

Auth.sessionIsExpired = false;
Auth.gAPILoaded = false;

/** Persist user information to a cookie so it can be used later */
const saveUser = (user) => {
  if (user) {
    let userData = getCookie('user') || {};
    let first_name, last_name, dob, email, cell_phone, gender;
    // omits the following keys when recreating this object
    userData = {
      ...userData,
      ...user,
      first_name,
      last_name,
      dob,
      email,
      cell_phone,
      gender
    };

    setCookie('user', userData);
  }
};

Auth.getProvider = () => {
  return getCookie('provider');
};

Auth.saveUserCookie = (user) => {
  saveUser(user);
};

/**
 * Makes a POST request to /api/session with the login information of the user
 * @param {object} data
 * @param {string} data.login_key
 * @param {string} data.password
 */
Auth.login = async ({ login_key, password }) => {
  const response = await ODAS.post('/api/session', { session: { login_key, password } });
  if (response?.data?.jwt) {
    Auth.saveToken(response.data.jwt);
  } else {
    return response;
  }
  const userRequest = await ODAS.get('/api/account');
  if (userRequest?.data?.user) {
    saveUser(userRequest?.data?.user);
    return userRequest?.data;
  } else {
    return response;
  }
};

/**
 * Makes a POST request to api/session/confirm with the password to refresh token
 * @param {object}
 */
Auth.confirm = async ({ password }) => {
  const data = await ODAS.post('/api/session/confirm', { session: { password } });
  if (data?.status == 200) {
    Auth.sessionIsExpired = false;
    const userRequest = await ODAS.get('/api/account');
    saveUser(userRequest?.data?.user);
    return userRequest?.data;
  } else {
    return;
  }
};

/**
 * Makes a POST request to /api/passwords with reset password data
 * @param {object} data
 */
Auth.reset = async (data) => {
  const response = await ODAS.post('/api/passwords', data);
  if (response) {
    return response;
  } else {
    return null;
  }
};

/**
 * Makes a POST request to /api/users with the signup information of the user
 * @param {Object} user - An object.
 * @param {string} user.email - Email to create the user
 * @param {string} user.password - Password for the user
 * @returns A data JSON object | or an error promise
 */
Auth.signup = async (user, args = {}) => {
  let userPost;
  try {
    userPost = await ODAS.post('/api/users', { user, ...args });
    if (userPost?.data?.jwt) {
      Auth.saveToken(userPost.data.jwt);
    } else {
      return userPost;
    }
  } catch (e) {
    return;
  }

  try {
    const accountGet = await ODAS.get('/api/account');
    saveUser(accountGet?.data?.user);
    accountGet.data['affiliation_list'] = userPost?.data?.affiliation_list;
    accountGet.data['suggested_affiliation'] = userPost?.data?.suggested_affiliation;
    return accountGet.data;
  } catch (e) {
    return userPost;
  }
};

/**
 * Stores the token after login or signup to be used in subsequent requests
 * @param {string} token JWT token returned from the API
 */
Auth.saveToken = (token) => {
  setCookie('jwt_token', token);
};
Auth.getToken = () => {
  return getCookie('jwt_token') || false;
};

// Auth.verifyToken = () => {
//   const token = getCookie('jwt_token')
//   if(token) {
//     if(Date.now() > JSON.parse(atob(token.split(".")[1])).exp*1000) {
//       setCookie('jwt_token', null);
//       Auth.isLoggedIn();
//       window.location = "/confirm-login?page="+ window.location.pathname
//     }
//   }
// }

Auth.expireSession = () => {
  Auth.sessionIsExpired = Date.now();
};

Auth.isLoggedIn = () => {
  const isLoggedin = !!getCookie('jwt_token');
  Auth.loggedIn = isLoggedin;
  return isLoggedin;
};

Auth.getCachedUser = () => {
  return getCookie('user') || false;
};

Auth.isAffiliatedPro = () => {
  return Auth.getCachedUser()?.affiliation?.is_approved === true;
};

/**
 * Method to GET /api/account/affiliation
 * @returns {Promise} returns a promise that resolves with user, and other account data
 */
Auth.getAffiliationInfo = async () => {
  try {
    const affiliationData = await ODAS.get('/api/account/affiliation');

    if (affiliationData) {
      if (affiliationData.message === 'Token Expired') {
      }

      saveUser(affiliationData.data.user);

      return affiliationData.data;
    }
  } catch (e) {
    // TODO: if there's an error, handle it
    throw e;
  }
};

/**
 * Method to GET /api/account and verify the user's account information
 * @returns {Promise} returns a promise that resolves with user, and other account data
 */
Auth.getAccountInfo = async () => {
  try {
    const accountData = await ODAS.get('/api/account', {}, { credentials: 'omit' });

    if (accountData) {
      if (accountData.message === 'Token Expired') {
      }
      if (accountData?.response?.user) {
        saveUser(accountData.response.user);
        return { user: accountData.response.user, isLoggedIn: true, needsConfirmation: false };
      }
    }
  } catch (e) {
    // TODO: if there's an error, handle it
    throw e;
  }
};

/**
 * Method to PUT /api/user to update user details
 * @param {object} data to update user field(s)
 * @returns {Promise} returns a promise that resolves with user, and other account data
 */
Auth.updateAccountInfo = async (data) => {
  try {
    const accountData = await ODAS.put('/api/user', data, { credentials: 'omit' });
    console.log({ accountData });

    if (accountData) {
      if (accountData.message === 'Token Expired') {
        console.log('Token Expired');
      }

      if (accountData?.response?.user) {
        saveUser(accountData.response.user);
        return { user: accountData.response.user, isLoggedIn: true, needsConfirmation: false };
      }
    }
  } catch (e) {
    // TODO: if there's an error, handle it
    console.warn(e);
    throw e;
  }
};

Auth.confirmPhoneDigitsForAgencyClient = ({ digits, agencyId, externalClientId }) => {
  let params = {
    digits: digits, // api endpoint expects this to be the last 4 digits of the client's cell_phone
    agency_id: agencyId,
    external_client_id: externalClientId
  };

  return ODAS.post('/api/session/confirm_phone', params).then((response) => {
    return response.response;
  });
};

/**
 * Delete the cookies and data associated with the user
 * And make a request to /api/session to cancel the session in the server
 * @returns undefined
 */
Auth.logoutUser = () => {
  deleteCookie('jwt_token');
  deleteCookie('user');
  deleteCookie('provider');
  deleteCookie('CSRF-TOKEN');
  if (Auth.gAPILoaded) {
    google.accounts.id.disableAutoSelect(); // records the user logged out status in cookies to prevent a UX dead loop.
  }
  return ODAS.delete('/api/session');
};

Auth.handleLoginWithGooglePlus = (authResponse) => {
  if (authResponse && authResponse.credential) {
    Auth.gAPILoaded = true;
    Auth.sessionIsExpired = false;
    return ODAS.post('/api/session/google_oauth2', {
      id_token: authResponse.credential
    }).then((data) => {
      saveUser(data?.data?.user);
      Auth.saveToken(data?.data?.jwt);
      return { ...(data?.data || {}), isCreation: data?.status === 201 };
    });
  }
};

Auth.changePassword = (passwordHash) => {
  return ODAS.put('/api/passwords/change_password', { change_password: passwordHash });
};

Auth.getUrlFor = (key) => {
  if (key === 'confirm-login') {
    return '/confirm-login?r=' + getUrlEncodedPath();
  } else {
    throw new Error('getUrlFor: unknown key: ' + key);
  }
};

Auth.isActiveDirectoryUser = () => {
  const provider = Auth.getProvider();
  return provider && provider.indexOf('saml_') === 0;
};

export default Auth;
