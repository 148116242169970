import botsList from '@/rails/config/static/bots.yml';
import isEmpty from 'lodash/isEmpty';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ReactDOMServer from 'react-dom/server';
import lang from '@/utils/Language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const unique = (list) => Array.from(new Set(list));

export const isObject = (obj) => {
  return obj !== undefined && obj !== null && obj.constructor == Object;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const hasName = (obj) => {
  return (typeof obj?.first_name === 'string' && !isEmpty(obj.first_name)) || (typeof obj?.last_name === 'string' && isEmpty(obj.last_name));
};

/**
 * options:
 *   onlyShowLastInitial: true | false
 *   surnameFirst: true | false
 */
export const displayName = (obj, options = {}) => {
  if (!obj) {
    return '';
  }
  if (hasName(obj)) {
    if (options.surnameFirst) {
      return alphabeticalCombineName(obj).trim();
    } else {
      return combineName(obj, options).trim();
    }
  } else {
    return obj.email || obj.cell_phone || '';
  }
};

const combineName = (obj, options) => {
  return (
    (!isEmpty(obj.first_name) ? obj.first_name : '') +
    ' ' +
    (!isEmpty(obj.last_name) ? (options.onlyShowLastInitial ? obj.last_name.substring(0, 1) : obj.last_name) : '')
  );
};

const alphabeticalCombineName = (obj) => {
  return (!isEmpty(obj.last_name) ? obj.last_name : '') + ', ' + (!isEmpty(obj.first_name) ? obj.first_name : '');
};

export const regexFilledField = '.*[^s]+.*';

export const emailOrPhone = (str) => {
  if (str == null) {
    return undefined;
  }
  if (str.match(/.+\@.+\..+/)) {
    return 'email';
  } else if (str.match(/\d{3}\D*\d{3}\D*\d{4}/)) {
    return 'cell_phone';
  } else {
    return undefined;
  }
};

/* Note: If changing this value, updates are also needed in the CSS 
   react-app/src/lahip/styles/_variables.scss -> $lahip-fixed-header-height */
export const FIXED_HEADER_HEIGHT = 120;

export const scrollIntoView = (element) => {
  if (typeof window !== 'undefined') {
    let header = document.querySelector('header');
    window.scrollTo({
      top:
        element.getBoundingClientRect().top +
        window.pageYOffset -
        (header && window.getComputedStyle(header).position === 'fixed' ? FIXED_HEADER_HEIGHT : 0),
      behavior: 'smooth'
    });
  }
};

export const getUrlParameter = (key) => {
  if (typeof window !== 'undefined') {
    let params = new URLSearchParams(window.location.search);
    return params.get(key);
  }
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

/**
 * Load a script and run a callback after it was loaded
 * @param script
 * @return Promise.resolve
 */
export const loadScript = (script) => {
  return new Promise((resolve, reject) => {
    const already = !!document.querySelectorAll(`[src="${script}"]`).length;
    if (already) {
      return resolve(true);
    }

    let GApi = document.createElement('script');
    GApi.setAttribute('src', script);
    document.body.appendChild(GApi);

    GApi.onload = () => {
      console.log('loading script loaded', script);
      resolve(true);
    };
  });
};

export const isBot = () => {
  return navigator.userAgent.toLowerCase().match(new RegExp(botsList.join('|').toLowerCase()));
};

/**
 * Parse content for matching key with a sub string
 * @param {string} content
 * @param {[[key, value], [...], [...], ...]} replaceWith
 * @returns
 */
export const parseContent = (content, replaceWith) => {
  return (content = replaceWith.map(([key, value]) => {
    return content.split(key).join(value);
  }));
};

export const getUrlEncodedPath = () => {
  return window
    ? encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search) + encodeURIComponent(window.location.hash)
    : '';
};

/**
 * Renders the Skeleton loading image for a given number of times.
 * @param {number} numOfTimes: displays the skeleton bar for a given number of times | Default: 1
 * @param {boolean} transparent: displays the skeleton transparent to the background | Default: false
 * @param {string} highlightColor: sets the highlight color of animating loading image | Default: white
 * @returns loading skeleton image
 */
export const renderSkeleton = (numOfTimes = 1, transparent = false, highlightColor = '#ffffff') => {
  const transparentHC = highlightColor + '1c';
  return [...Array(numOfTimes)].map((num, index) => {
    return (
      <SkeletonTheme baseColor={transparent ? 'transparent' : ''} highlightColor={transparent ? transparentHC : highlightColor} key={index}>
        <Skeleton />
      </SkeletonTheme>
    );
  });
};

/**
 * This is the external component (FontAwesom) rendered its own, hence need explicite parsing
 * @returns parsed content with dom element
 */
export const renderContentWithPopoutIcon = () => {
  const popoutIcon = <FontAwesomeIcon icon={['fas', 'up-right-from-square']} />;
  return [['{{popoutIcon}}', ReactDOMServer.renderToString(popoutIcon)]];
};

/**
 * Load a script and run a callback after it was loaded
 * @param fileExtension - extension of the file being printed/previewed (e.g, pdf/image)
 * @param fileType - type of file (e.g, base64, url)
 * @param file - file content or URL
 */

export const printDocumentFromURL = (url) => {
  const newTab = window.open(url, '_blank');
};

export const printDocumentFromFile = ({ fileExtension, fileType, file }) => {
  if (fileExtension.includes('pdf')) {
    const binaryData = atob(file);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const newWindow = window.open();
    newWindow.document.body.innerHTML = `<iframe src="${blobUrl}" style="width: 100%; height: 100vh;"></iframe>`;
    const iframe = newWindow.document.querySelector('iframe');
    iframe.onload = () => {
      newWindow.focus();
      iframe.contentWindow.print();
    };
  } else {
    const newWindow = window.open('', '_blank');
    newWindow.document.write('<html><head><title>Print Image</title></head><body>');
    newWindow.document.write(`<img src="data:image/png;base64,${file}" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">`);
    //Wait for 100ms untill image is opened in new window
    setTimeout(() => {
      newWindow.focus();
      newWindow.print();
    }, 100);
  }
};

/**
 * Returns url string according to the selected language
 * @param {string} url: url string
 * @returns url string according to the selected language
 */
export const getLocalizedImageSrc = (url) => {
  if (typeof url != 'object') {
    return url;
  }
  return url[lang.getCurrentLanguage()];
};

/**
 * Reload helpscout for a selected language
 * @param {string} lang: selected language
 */

const beacon_token = {
  en: '2d0aacd0-2554-4f20-ae22-6fd73e07f878',
  es: '8df8238c-fac1-4907-a21b-610b82222540',
  pro: '317e5e4f-f53e-41ad-b3f8-cf60e99c8b9e',
  frf_en: '17cf0fc4-dabf-458c-a154-432169e2c433',
  frf_es: '24730137-e8aa-4b27-8bd7-7f644dea6f7c'
};

export const renderHelpScout = (token) => {
  if (window.Beacon) {
    window.Beacon('destroy');
    setTimeout(() => {
      if (beacon_token[token]) {
        window.Beacon('init', beacon_token[token]);
      } else {
        window.Beacon('init', beacon_token['en']);
      }
    }, 200);
  }
};

export const initBeacon = () => {
  window.Beacon ||
    !(function (e, t, n) {
      function a() {
        var e = t.getElementsByTagName('script')[0],
          n = t.createElement('script');
        (n.type = 'text/javascript'), (n.async = !0), (n.src = 'https://beacon-v2.helpscout.net'), e.parentNode.insertBefore(n, e);
      }
      if (
        ((e.Beacon = n =
          function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
        (n.readyQueue = []),
        'complete' === t.readyState)
      )
        return a();
      e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
    })(window, document, window.Beacon || function () {});
};
