const collectionHelpers = {
  decodePermission: (collection) => {
    switch (collection.user_permission) {
      case 0:
        return 'owner';
        break;
      case 1:
        return 'editor';
        break;
      default:
        return 'viewer';
        break;
    }
  },
  totalResources: (collection) => {
    return collection.resource_count_total;
  }
};

export default collectionHelpers;
