import { useMemo, useState } from 'react';
import { extend, each, keys } from 'lodash';
import Account from '@/utils/api/odas/account';
import { localStorageService } from '@/utils/local_storage';
import useHoneybadger from './useHoneybadger';

export default function useBootstrapCLR() {
  const { notifyHoneybadger } = useHoneybadger();
  const [isLoadingClrData, setIsLoadingClrData] = useState(false);
  const [bootstrapCLRData, setBootstrapCLRData] = useState(null);
  const [partnerOrgs, setPartnerOrgs] = useState(null);

  const storedNetworks = localStorageService.get('referral_networks') || { networks: {} };

  // interface for working with certain data we want to keep in local storage
  const cachedNetworks = useMemo(() => {
    const data = storedNetworks;
    const save = () => localStorageService.set('referral_networks', data);
    // returns an api for dealing with the stored data
    return (networkId, args, opts = {}) => {
      if (networkId === 'save') return save();
      const network = data.networks[networkId] || {};
      if (typeof args === 'undefined') {
        // get
        return network;
      } else {
        // set
        data.networks[networkId] = extend({}, network, args);
        if (opts.save !== false) save();
      }
    };
  }, [storedNetworks]);

  const getCLRConfig = async () => {
    try {
      if (isLoadingClrData) return;
      setIsLoadingClrData(true);
      const resp = await Account.bootstrapCLR();
      const data = {};
      each(resp?.data?.network_data, (network) => {
        data[network.network_id] = formatNetwork(network);
      });
      setBootstrapCLRData(data);
      setPartnerOrgs(resp?.data?.partner_orgs || []);
      return data;
    } catch (ex) {
      console.warn(ex);
      notifyHoneybadger(ex);
    } finally {
      setIsLoadingClrData(false);
    }
  };

  // format anything for convenient access, e.g. a map of the network ids for faster lookup
  const formatNetwork = (network) => {
    network.my_agency_summary = null;
    network.agency_id_map = {};
    each(network.in_network_agencies || [], (data) => {
      network.agency_id_map[data.fetchable_id] = true;
      if (data.fetchable_id == network.fetchable_id) {
        network.my_agency_summary = data;
      }
    });
    // TODO why not save? what's the intent here?
    cachedNetworks(network.network_id, { orgIds: keys(network.agency_id_map) }, { save: false });
    return network;
  };

  return {
    getCLRConfig,
    isLoadingClrData,
    bootstrapCLRData,
    partnerOrgs
  };
}
