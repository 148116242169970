import filter from 'lodash/filter';
import { displayName } from '@/utils/utils';
import find from 'lodash/find';

const aeHelpers = {
  activeStatuses: ['planned', 'accessed'],
  archivedStatuses: ['unsuccessful', 'rejected'],
  clrStatuses: ['sent', 'acknowledged', 'assigned', 'reassigned', 'reminder', 'in_progress'],
  completedStatuses: ['utilized'],
  getGroupFromStatus: (status) => {
    if (aeHelpers.clrStatuses.indexOf(status) >= 0) {
      return 'clrStatus';
    } else {
      return status;
    }
  },
  hasReferralOrigin: (ae) => {
    return ae.referral_origin != null;
  },
  hasSupporterOrigin: (ae) => {
    return filter(ae.origins, { origin_type: 'SupporterUpdate' }).length > 0;
  },
  hasReferralUpdateOrigin: (ae) => {
    return filter(ae.origins, { origin_type: 'ReferralUpdate' }).length > 0;
  },
  referralUpdateOrigin: (ae) => {
    return filter(ae.origins, { origin_type: 'ReferralUpdate' })[0];
  },
  isActive: (ae) => {
    return aeHelpers.activeStatuses.concat(aeHelpers.clrStatuses).indexOf(ae.status) > -1;
  },
  isArchived: (ae) => {
    return aeHelpers.archivedStatuses.indexOf(ae.status) > -1;
  },
  isDeletable: function (ae) {
    return aeHelpers.isActive(ae) && !aeHelpers.hasReferralOrigin(ae) && !aeHelpers.hasSupporterOrigin(ae) && !ae.is_common_app;
  },
  isClosedLoopReferral: (referral) => {
    if (!referral || !referral.referral_origin) {
      return false;
    }
    return referral.referral_origin.origin_type === 'ClosedLoopReferral';
  },
  getReferralNetworkId: (referral) => {
    if (!referral || !referral.referral_origin || referral.referral_origin.origin_type !== 'ClosedLoopReferral') {
      return null;
    }

    return referral.referral_origin.referral_network_id;
  },
  needsReassignment: function (referral) {
    return referral && ['assigned', 'reassigned', 'in_progress'].indexOf(referral.status) >= 0 && referral.assigned_to_supporter_id == null;
  },
  showReassignmentButton: function (referral) {
    return referral && ['sent', 'planned', 'accessed', 'acknowledged', 'reminder'].indexOf(referral.status) < 0;
  },
  hasHistoryOfStatus: function (referral, status) {
    return (
      referral.meta_data &&
      referral.meta_data.update_history &&
      find(referral.meta_data.update_history, function (update_event) {
        return update_event.status == status;
      }) != null
    );
  },
  isCompleted: (ae) => {
    return aeHelpers.completedStatuses.indexOf(ae.status) > -1;
  },
  supporterId: ({ accessEvent, accessEventOrigin }) => {
    let supporterId = false;
    let origin = accessEvent && accessEvent.referral_origin ? accessEvent.referral_origin : accessEventOrigin;

    if (origin?.supporter) {
      supporterId = origin.supporter.id;
    }

    return supporterId;
  },
  supporterName: ({ accessEvent, accessEventOrigin, ...options }) => {
    let supporterName = 'N/A';
    let origin = accessEvent && accessEvent.referral_origin ? accessEvent.referral_origin : accessEventOrigin;

    if (origin?.supporter) {
      supporterName = displayName(origin.supporter);
    }

    return supporterName;
  },
  utilizationQuestionAnswered: function (ae) {
    return ae && aeHelpers.completedStatuses.concat(aeHelpers.archivedStatuses).indexOf(ae.status) >= 0;
  },
  getAssigneeName: (referral) => {
    if (!referral || !referral.assigned_to_supporter) {
      return '';
    }
    return ((referral.assigned_to_supporter.first_name || '') + ' ' + (referral.assigned_to_supporter.last_name || '')).trim();
  },
  extractNotesFromReferral: (referral) => {
    if (referral && referral.meta_data && referral.meta_data.update_history && referral.meta_data.update_history.length > 0) {
      for (var i = referral.meta_data.update_history.length - 1; i >= 0; i--) {
        if (referral.meta_data.update_history[i].notes) {
          return referral.meta_data.update_history[i].notes;
        }
      }
    }
    return '';
  }
};

export default aeHelpers;
