import accessEventHelpers from './accessEvent';
import accountHelpers from './account';
import bookmarkHelpers from './bookmark';
import calendarHelpers from './calendar';
import collectionHelpers from './collection';
import layoutHelpers from './layout';
import locationHelpers from './location';
import modalHelpers from './modal';
import propertyHelpers from './property';
import ratingHelpers from './rating';
import resourceHelpers from './resource';
import searchHelpers from './search';
import tagHelpers from './tag';
import teamHelpers from './team';
import themeHelpers from './theme';
import uploadedDocumentHelpers from './uploadedDocument';
import organizationHelpers from './organization';
import reportHelpers from './report';
import { DateHelper } from './date';
import csvHelper from './csv';

export const compiling_uri = (uri) => {
  return `${process.env.REACT_APP_ROOT_COMPILING_PATH}/${uri}`;
};

export const angular_host_uri = (uri) => {
  return `${process.env.REACT_APP_ODAS_HOST}/${uri}`;
};

export const isPresent = (variable) => {
  return variable != undefined && variable !== '' && variable != null;
};

export const pathsInReact = [
  // using the same regex from nginx, but this is set as an array in case it make sense to list each path separately in the future
  // NOTE: changes made here should be reflected in the dev-env-dockerized repo /nginx-staging/auth.conf (when ready for staging)
  // and also made in this repo /nginx.conf (when ready to deploy to production)
  /^\/(stayhousedbayarea|familyresourcefinder(\/results\/)?|home|dashboard|browse|signup|login|confirm-login|onboarding-saml|referrals\/new|(org\/[^\/]+\/reports\/referral-summary)|plan|assessments\/pearls|collections(\/new)?$|(clients(\/$)?(\/\d+(\/)?)?(?!.+)))/
];

export {
  accessEventHelpers,
  accountHelpers,
  bookmarkHelpers,
  calendarHelpers,
  collectionHelpers,
  layoutHelpers,
  locationHelpers,
  modalHelpers,
  propertyHelpers,
  ratingHelpers,
  resourceHelpers,
  searchHelpers,
  tagHelpers,
  teamHelpers,
  themeHelpers,
  uploadedDocumentHelpers,
  DateHelper,
  organizationHelpers,
  reportHelpers,
  csvHelper
};
