import ODRS from '@/utils/api/odrs';
import moment from 'moment';
import each from 'lodash/each';

// An update was made to the structure of the file, but this assignment logic supports backwards
// compatibility in cases where the old file structure is cached for a user
// or as temporary cover if a server has issues updating the file
const tags = typeof window !== 'undefined' ? window.tags?.tags || window.tags || {} : {};

const tagHelpers = {
  areaAndCategoryFromTag: (tag) => {
    let areaToReturn, categoryToReturn;
    if (typeof tag !== 'undefined') {
      each(tags, (subcategories, area) => {
        if (area?.toLowerCase() == tag?.toLowerCase()) {
          areaToReturn = area;
        }
        each(subcategories, (tags, category) => {
          var lowerCaseTags = [];
          each(tags, (tag) => {
            lowerCaseTags.push(tag?.toLowerCase());
          });
          if (category?.toLowerCase() == tag?.toLowerCase() || lowerCaseTags.indexOf(tag?.toLowerCase()) > -1) {
            areaToReturn = area;
            categoryToReturn = category;
          }
        });
      });
    }
    return [areaToReturn, categoryToReturn];
  },
  areaToSlug: (area) => {
    if (area) {
      let parts = area.toLowerCase().split(' ');
      return parts[0];
    } else {
      return 'rocket'; //default area slug
    }
  },
  tags,
  reOrderTags: (tag) => {
    // This is hardcoded order, matches with Angular
    const order = ['Urgent', 'Family & Household', 'Food', 'Health', 'Housing', 'Education', 'Legal', 'Employment', 'Money'];
    return order.reduce((accumulator, key) => {
      accumulator[key] = tag[key];
      return accumulator;
    }, {});
  },
  icon: (areaOrResourceType) => {
    const DEFAULT_ICON_CLASS = 'fa-rocket';
    const DEFAULT_ICON_UNICODE = '\uf135';
    const DEFAULT_ICON_COLOR = '#006782';

    if (areaOrResourceType == undefined) {
      areaOrResourceType = '';
    } else {
      areaOrResourceType = areaOrResourceType.toLowerCase();
    }
    switch (areaOrResourceType) {
      case 'urgent':
        return {
          class: 'fa-warning',
          unicode: '\uf071',
          color: '#F4A72D'
        };
        break;
      case 'emergency':
        return {
          class: 'fa-warning',
          unicode: '\uf071',
          color: '#F4A72D'
        };
        break;
      case 'family & household':
        return {
          class: 'fa-child',
          unicode: '\uf1ae',
          color: '#9D73C7'
        };
        break;
      case 'food':
        return {
          class: 'fa-cutlery',
          unicode: '\uf0f5',
          color: '#88A0E6'
        };
        break;
      case 'health':
        return {
          class: 'fa-plus-square',
          unicode: '\uf0fe',
          color: '#EA5D59'
        };
        break;
      case 'housing':
        return {
          class: 'fa-home',
          unicode: '\uf015',
          color: '#5CB8E6'
        };
        break;
      case 'education':
        return {
          class: 'fa-graduation-cap',
          unicode: '\uf19d',
          color: '#69D269'
        };
        break;
      case 'legal':
        return {
          class: 'fa-legal',
          unicode: '\uf0e3',
          color: '#E172AD'
        };
        break;
      case 'money':
        return {
          class: 'fa-usd',
          unicode: '\uf155',
          color: '#5AD4C0'
        };
        break;
      case 'employment':
        return {
          class: 'fa-briefcase',
          unicode: '\uf0b1',
          color: '#EF7F7F'
        };
        break;
      case 'one degree news':
        return {
          class: 'fa-newspaper-o',
          unicode: '\uf0b1',
          color: '#979797'
        };
        break;
      case 'all':
        return {
          class: 'fa-arrow-circle-right',
          unicode: '\uf0a9',
          color: '#979797'
        };
        break;
      case 'more':
        return {
          class: 'fa-ellipsis-h',
          unicode: '\uf141',
          color: '#EF7E7F'
        };
        break;
      case 'organization':
        return {
          class: 'fa-sitemap',
          unicode: '\uf0e8',
          color: '#0093CC'
        };
        break;
      case 'collection':
        return {
          class: 'fa-book',
          unicode: '\uf02d',
          color: '#0093CC'
        };
        break;
      case 'category':
        return {
          class: 'fa-folder-open',
          unicode: '\uf115',
          color: '#0093CC'
        };
        break;
      case 'opportunity':
        return {
          class: DEFAULT_ICON_CLASS,
          unicode: DEFAULT_ICON_UNICODE,
          color: DEFAULT_ICON_COLOR
        };
        break;
      case 'tag':
        return {
          class: 'fa-search',
          unicode: '\uf002',
          color: '#979797'
        };
        break;
      case 'assessment':
        return {
          class: 'fa-comment-o',
          unicode: '\uf0e5',
          color: '#979797'
        };
        break;
      case 'topicpage':
        return {
          class: 'fa-columns',
          unicode: '\uf0db',
          color: '#979797'
        };
        break;
      case 'food-alter':
        return {
          class: 'fa-shopping-basket',
          unicode: '\uf0f0',
          color: '#88A0E6'
        };
        break;
      case 'health-alter':
        return {
          class: 'fa-user-md',
          unicode: '\uf291',
          color: '#EA5D58'
        };
        break;
      case 'education-alter':
        return {
          class: 'fa-soccer-ball-o',
          unicode: '\uf549',
          color: '#69D269'
        };
        break;
      default:
        return {
          class: DEFAULT_ICON_CLASS,
          unicode: DEFAULT_ICON_UNICODE,
          color: DEFAULT_ICON_COLOR
        };
        break;
    }
  },
  getTags: () => {
    return tags;
  }
};

export default tagHelpers;
