const Keys = {};
if (process.env.ENV == 'production') {
  Object.assign(Keys, {
    googlePlus: {
      clientId: '632616072992-krtfrdl8uuufqu498409i3doeqp6vek4.apps.googleusercontent.com'
    },
    odas: {
      host: 'https://www.1degree.org',
      defaultImage: 'http://s3-us-west-2.amazonaws.com/onedegree-public/One-Degree-logo_1200x630.jpg'
    },
    googleCloud: {
      geolocationUrl: 'https://us-central1-web-client-dev.cloudfunctions.net/geolocation'
    }
  });
} else if (process.env.ENV == 'staging') {
  Object.assign(Keys, {
    googlePlus: {
      clientId: '632616072992-v5qpm44n0qg45ov02rh6r1t8kllrefmc.apps.googleusercontent.com'
    },
    odas: {
      host: process.env.ODAS_URL_HOST_WITH_PORT,
      defaultImage: 'http://s3-us-west-2.amazonaws.com/onedegree-public/One-Degree-logo_1200x630.jpg',
      jwtHeader: 'Demo-Authorization' //Prevent the demo basic auth header from clobbering the jwt auth header
    },
    googleCloud: {
      geolocationUrl: 'https://us-central1-web-client-dev.cloudfunctions.net/geolocation'
    }
  });
} else if (process.env.ENV == 'development') {
  Object.assign(Keys, {
    googlePlus: {
      clientId: '632616072992-olegdkhf8n3oh292kmid3987ssnna5oe.apps.googleusercontent.com'
    },
    odas: {
      host: 'http://localhost:3000',
      ssr_host: 'http://odas:3000',
      defaultImage: 'http://s3-us-west-2.amazonaws.com/onedegree-public/One-Degree-logo_1200x630.jpg'
    },
    googleCloud: {
      geolocationUrl: 'https://us-central1-web-client-dev.cloudfunctions.net/geolocation-development'
    }
  });
}

Keys.fn = {
  odasSSR: () => Keys.odas.ssr_host || Keys.odas.host
};

export default Keys;
