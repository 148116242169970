/**
 Creating a localStorage wrapper that will be compatible with Angular so data can be passed between React and Angular.
 This does two key operations to conform to Angular's expectations:
   1. passes all values through JSON.stringfy or JSON.parse regardless of their data type
   2. prepends 'ls.' to all item keys
 Syntax should then be the same in both Angular and React.
 NOTE: 'angular-local-storage' has a fallback to cookies if localStorage isn't supported - this functionality is not replicated here.
 NOTE: Some localStorage items from Angular are passed twice through JSON.stringify which seems to be unnecessary.  Some of those places have been updated in Angular, but since there may still be some localStorage for users that is double stringify-ed, adding a second JSON.parse attempt here to account for that.
 TODO: As other localStorage services from Angular are ported to React, update them to only JSON.stringify once and to properly handle reading both single and double JSON.stringify-ed values (see app/assets/javascripts/services/location.js.erb#loadLocationFromCache)
 TODO: Once migration to React is complete, enough time should have passed to that this redunant JSON.parse is no longer needed.
 */

const getKeyPrefix = (storage) => {
  return storage === 'sessionStorage' ? 'ss' : 'ls';
};

const get = (storage, key) => {
  if (typeof window !== 'undefined') {
    try {
      const storedContent = window[storage].getItem(getKeyPrefix(storage) + '.' + key);
      if (!storedContent) {
        return null;
      } else if (storedContent === 'undefined') {
        return undefined; // I assume this was double-encoded?
      }
      const parsedContent = JSON.parse(storedContent);
      if (typeof parsedContent === 'string') {
        try {
          return JSON.parse(parsedContent); // TODO remove double-encoding if we can (coming from angular?)
        } catch (err) {
          return parsedContent;
        }
      } else {
        return parsedContent;
      }
    } catch (err) {
      DEBUG && console.log(err);
      return null;
    }
  }
};

const set = (storage, key, value) => {
  if (typeof window !== 'undefined') {
    window[storage].setItem(getKeyPrefix(storage) + '.' + key, JSON.stringify(value));
  }
};

const remove = (storage, key) => {
  if (typeof window !== 'undefined') {
    window[storage].removeItem(getKeyPrefix(storage) + '.' + key);
  }
};

// only clear keys created by the app
const clearStorage = (storage) => {
  for (let i = 0; i < window[storage].length; i++) {
    let key = window[storage].key(i);
    if (key && key.indexOf(getKeyPrefix(storage) + '.') === 0) {
      window[storage].removeItem(key);
    }
  }
};

export const localStorageService = {
  get: (key) => {
    return get('localStorage', key);
  },
  set: (key, value) => {
    return set('localStorage', key, value);
  },
  remove: (key) => {
    return remove('localStorage', key);
  },
  clear: () => {
    clearStorage('localStorage');
  }
};

export const sessionStorageService = {
  get: (key) => {
    return get('sessionStorage', key);
  },
  set: (key, value) => {
    return set('sessionStorage', key, value);
  },
  remove: (key) => {
    return remove('sessionStorage', key);
  },
  clear: () => {
    clearStorage('sessionStorage');
  }
};
