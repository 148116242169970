import ODRS from '@/utils/api/odrs';

const Fetchable = {
  fetchResourcesById: (resourceCollection, ids, params) => {
    return ODRS.getResourcesById(resourceCollection, ids, params).then((response) => {
      return response.data;
    });
  },
  getTitlesOnly: (resourceCollectionName, id, params) => {
    params = params || {};
    params.titles_only = true;
    return ODRS.getResourceById(resourceCollectionName, id, params).then((response) => {
      return response.data;
    });
  }
};

export default Fetchable;
