/**
 * This is ported from the angular SearchHelpers constant.
 */

export default {
  // Enable or disable search filter sections - this config is only intended to include filter sections that are
  // dependent on the search location and is not an exhaustive list of all filter sections (see `filterSections` below for that).
  // A "countyArea" is an administrative level more specific than "county"
  // In NYC this is a "borough", in other places it is a "parish"
  enabledFilters: {
    CA: {
      countyFiltering: true,
      countyAreaFiltering: false,
      earnPropertyFiltering: false,
      emergencyFiltering: false,
      costAndFinAidFiltering: true,
      personalSituationFiltering: true,
      raceEthnicityFiltering: true,
      programFiltering: true,
      familySituationFiltering: true,
      coordinatedEntryFiltering: false
    },
    MI: {
      countyFiltering: true,
      countyAreaFiltering: false,
      earnPropertyFiltering: true,
      emergencyFiltering: false,
      costAndFinAidFiltering: true,
      personalSituationFiltering: true,
      raceEthnicityFiltering: true,
      programFiltering: true,
      familySituationFiltering: true,
      coordinatedEntryFiltering: false
    },
    NY: {
      countyFiltering: false, // the only county we have resources for currently is NYC so don't show the county filter
      countyAreaFiltering: true, // NYC has several boroughs
      earnPropertyFiltering: true,
      emergencyFiltering: false,
      costAndFinAidFiltering: true,
      personalSituationFiltering: true,
      raceEthnicityFiltering: true,
      programFiltering: false,
      coordinatedEntryFiltering: false
    },
    NM: {
      countyFiltering: true,
      countyAreaFiltering: false,
      earnPropertyFiltering: false,
      emergencyFiltering: false,
      costAndFinAidFiltering: true,
      personalSituationFiltering: true,
      raceEthnicityFiltering: true,
      programFiltering: true,
      familySituationFiltering: true,
      coordinatedEntryFiltering: false
    },
    DEFAULT: {
      countyFiltering: false,
      countyAreaFiltering: false,
      earnPropertyFiltering: false,
      emergencyFiltering: false,
      costAndFinAidFiltering: true,
      personalSituationFiltering: true,
      raceEthnicityFiltering: true,
      programFiltering: true,
      familySituationFiltering: true,
      coordinatedEntryFiltering: false
    }
  },

  filterSections: [
    'ageOptions',
    // "communityOptions",
    'citizenshipOptions',
    'coordinatedEntryFilterOptions',
    'costAndFinAidOptions',
    'personalSituationOptions',
    'raceEthnicityOptions',
    'distanceOptions',
    'earnOptions',
    'employmentEducationOptions',
    'genderOptions',
    'languageOptions',
    'militaryOptions',
    'programOptions',
    'familySituationOptions',
    'safespaceOptions',
    'emergencyFilterOptions'
  ],

  distanceOptions: ['1.60934', '4.82803', '8.04672', '16.0934', '40.2336', '80.4672', '160.934'],

  costAndFinAidOptions: ['cost-free', 'fin-aid-waiver', 'accepts-medicaid', 'fin-aid-sliding-scale'],

  employmentEducationOptions: ['elig-employed-part-time', 'elig-employed-full-time', 'elig-hs-degree', 'elig-not-in-school'],

  genderOptions: ['elig-women', 'elig-men', 'elig-nb-gnc'],

  safespaceOptions: ['safespaces-transgender', 'safespaces-lgbt'],

  citizenshipOptions: ['citizenship-available-everyone'],

  militaryOptions: ['elig-veteran'],

  topProgramOptions: ['elig-medicaid', 'elig-ssi', 'elig-snap'],

  programOptions: [
    'elig-ca-healthy-families', // CA healthy
    'elig-liheap', // energy assist
    'elig-ssi', // receives ssi
    'elig-snap', // food stamps
    'elig-tanf', // welfare
    'elig-medicaid', // Medicaid/
    'elig-wic' // wic
  ],

  topFamilySituationOptions: ['elig-parents', 'elig-mothers', 'elig-pregnant'],

  familySituationOptions: ['elig-married', 'elig-mothers', 'elig-fathers', 'elig-parents', 'elig-pregnant', 'elig-single-parent'],

  ageOptions: ['elig-youth', 'elig-adults', 'elig-seniors'],

  earnOptions: ['earn-industry-cert', 'earn-college-credits'],

  topLanguageOptions: ['lang-spanish', 'lang-cantonese', 'lang-mandarin', 'lang-arabic'],

  languageOptions: [
    'lang-all-languages-by-interpreter',
    'lang-somali',
    'lang-spanish',
    'lang-mandarin',
    'lang-cantonese',
    'lang-vietnamese',
    'lang-tagalog',
    'lang-korean',
    'lang-hindi',
    'lang-russian',
    'lang-farsi',
    'lang-thai',
    'lang-laotian',
    'lang-khmer',
    'lang-burmese',
    'lang-punjabi',
    'lang-mien',
    'lang-urdu',
    'lang-nepali',
    'lang-bengali',
    'lang-arabic',
    'lang-bhutanese',
    'lang-hmong',
    'lang-karen',
    'lang-armenian',
    'lang-chiu-chow',
    'lang-ukrainian',
    'lang-karenni',
    'lang-kiswahili',
    'lang-fiji',
    'lang-cambodian',
    'lang-french',
    'lang-dari',
    'lang-tibetan',
    'lang-mongolian',
    'lang-japanese',
    'lang-tongan',
    'lang-italian',
    'lang-amharic',
    'lang-portuguese',
    'lang-bosnian',
    'lang-taiwanese',
    'lang-toisanese',
    'lang-romanian',
    'lang-serbian',
    'lang-german',
    'lang-pashto',
    'lang-eritrean',
    'lang-greek',
    'lang-tigrigna',
    'lang-croatian',
    'lang-american-sign-language',
    'lang-berber',
    'lang-ilocano',
    'lang-samoan',
    'lang-senufo',
    'lang-tigrinya',
    'lang-wolof',
    'lang-fukienese',
    'lang-indonesian',
    'lang-telugu',
    'lang-cebuano'
  ],

  topPersonalSituationOptions: ['elig-disabled', 'elig-immigrant', 'elig-domestic-abuse-survivor', 'elig-homeless', 'elig-incarceration'],

  personalSituationOptions: [
    'elig-domestic-abuse-survivor',
    'elig-lgbtq',
    'elig-homeless',
    'elig-disabled',
    'elig-foster-youth',
    'elig-hiv-aids',
    'elig-human-trafficking-victim-survivor',
    'elig-sex-worker',
    'elig-immigrant',
    'elig-sexual-assault-survivor',
    'elig-incarceration',
    'elig-veteran'
  ],

  raceEthnicityOptions: ['elig-arab', 'elig-api', 'elig-african-american', 'elig-latino', 'elig-native-american'],

  // topCommunityOptions: [
  //   "community-available-everyone",
  //   "community-mothers",
  //   "community-immigrant",
  //   "community-domestic-violence-victim-survivor",
  //   "community-pregnant",
  //   "community-disabled"
  // ],

  // communityOptions: [
  //   "community-daca-recipient-seeker",
  //   "community-detained-immigrant",
  //   "community-disabled",
  //   "community-african-american",
  //   "community-api",
  //   "community-arab",
  //   "community-asylum-seeker",
  //   "community-christian",
  //   "community-domestic-violence-victim-survivor",
  //   "community-fathers",
  //   "community-foster-youth",
  //   "community-hiv-aids",
  //   "community-homeless",
  //   "community-immigrant",
  //   "community-jewish",
  //   "community-latino",
  //   "community-military-families",
  //   "community-mothers",
  //   "community-muslim",
  //   "community-native-american",
  //   "community-parents",
  //   "community-post-incarceration",
  //   "community-pregnant",
  //   "community-public-transit-riders",
  //   "community-refugee",
  //   "community-undocumented",
  //   "community-veteran",
  //   "community-human-trafficking-victim-survivor",
  //   "community-sex-worker",
  //   "community-sexual-assault-survivor",
  //   "community-available-everyone"
  // ],

  swnmServiceAreaCountyOptions: ['service-county-new-mexico-catron', 'service-county-new-mexico-grant', 'service-county-new-mexico-hidalgo'],

  detroitServiceAreaCountyOptions: [
    'service-county-michigan-livingston',
    'service-county-michigan-macomb',
    'service-county-michigan-monroe',
    'service-county-michigan-oakland',
    'service-county-michigan-st-clair',
    'service-county-michigan-washtenaw',
    'service-county-michigan-wayne'
  ],

  nyServiceAreaCountyOptions: [
    'service-county-new-york-new-york-city',
    'service-county-new-york-albany',
    'service-county-new-york-allegany',
    'service-county-new-york-broome',
    'service-county-new-york-cattaraugus',
    'service-county-new-york-cayuga',
    'service-county-new-york-chautauqua',
    'service-county-new-york-chemung',
    'service-county-new-york-chenango',
    'service-county-new-york-clinton',
    'service-county-new-york-columbia',
    'service-county-new-york-cortland',
    'service-county-new-york-delaware',
    'service-county-new-york-dutchess',
    'service-county-new-york-erie',
    'service-county-new-york-essex',
    'service-county-new-york-franklin',
    'service-county-new-york-fulton',
    'service-county-new-york-genesee',
    'service-county-new-york-greene',
    'service-county-new-york-hamilton',
    'service-county-new-york-herkimer',
    'service-county-new-york-jefferson',
    'service-county-new-york-kings',
    'service-county-new-york-lewis',
    'service-county-new-york-livingston',
    'service-county-new-york-madison',
    'service-county-new-york-monroe',
    'service-county-new-york-montgomery',
    'service-county-new-york-nassau',
    'service-county-new-york-niagara',
    'service-county-new-york-oneida',
    'service-county-new-york-onondaga',
    'service-county-new-york-ontario',
    'service-county-new-york-orange',
    'service-county-new-york-orleans',
    'service-county-new-york-oswego',
    'service-county-new-york-otsego',
    'service-county-new-york-putnam',
    'service-county-new-york-rensselaer',
    'service-county-new-york-richmond',
    'service-county-new-york-rockland',
    'service-county-new-york-saratoga',
    'service-county-new-york-schenectady',
    'service-county-new-york-schoharie',
    'service-county-new-york-schuyler',
    'service-county-new-york-seneca',
    'service-county-new-york-st-lawrence',
    'service-county-new-york-steuben',
    'service-county-new-york-suffolk',
    'service-county-new-york-sullivan',
    'service-county-new-york-tioga',
    'service-county-new-york-tompkins',
    'service-county-new-york-ulster',
    'service-county-new-york-warren',
    'service-county-new-york-washington',
    'service-county-new-york-wayne',
    'service-county-new-york-westchester',
    'service-county-new-york-wyoming',
    'service-county-new-york-yates'
  ],

  caServiceAreaCountyOptions: [
    'service-county-california-san-francisco',
    'service-county-california-alameda',
    'service-county-california-contra-costa',
    'service-county-california-san-mateo',
    'service-county-california-alpine',
    'service-county-california-amador',
    'service-county-california-butte',
    'service-county-california-calaveras',
    'service-county-california-colusa',
    'service-county-california-del-norte',
    'service-county-california-el-dorado',
    'service-county-california-fresno',
    'service-county-california-glenn',
    'service-county-california-humboldt',
    'service-county-california-imperial',
    'service-county-california-inyo',
    'service-county-california-kern',
    'service-county-california-kings',
    'service-county-california-lake',
    'service-county-california-lassen',
    'service-county-california-los-angeles',
    'service-county-california-madera',
    'service-county-california-marin',
    'service-county-california-mariposa',
    'service-county-california-mendocino',
    'service-county-california-merced',
    'service-county-california-modoc',
    'service-county-california-mono',
    'service-county-california-monterey',
    'service-county-california-napa',
    'service-county-california-nevada',
    'service-county-california-orange',
    'service-county-california-placer',
    'service-county-california-plumas',
    'service-county-california-riverside',
    'service-county-california-sacramento',
    'service-county-california-san-benito',
    'service-county-california-san-bernardino',
    'service-county-california-san-diego',
    'service-county-california-san-joaquin',
    'service-county-california-san-luis-obispo',
    'service-county-california-santa-barbara',
    'service-county-california-santa-clara',
    'service-county-california-santa-cruz',
    'service-county-california-shasta',
    'service-county-california-sierra',
    'service-county-california-siskiyou',
    'service-county-california-solano',
    'service-county-california-sonoma',
    'service-county-california-stanislaus',
    'service-county-california-sutter',
    'service-county-california-tehama',
    'service-county-california-trinity',
    'service-county-california-tulare',
    'service-county-california-tuolumne',
    'service-county-california-ventura',
    'service-county-california-yolo',
    'service-county-california-yuba'
  ],

  emergencyFilterOptions: [],

  coordinatedEntryFilterOptions: ['coordinated-entry-site'],

  rangeProperties: [
    {
      metaName: 'age',
      minProperty: 'elig-age-or-over',
      maxProperty: 'elig-age-or-under'
    },
    {
      metaName: 'annual-income',
      minProperty: 'elig-annual-income-or-over',
      maxProperty: 'elig-annual-income-or-under'
    }
  ],

  defaultSearchSetting: function (key) {
    switch (key) {
      case 'r':
        return this.distanceOptions[2];
    }
  },

  allProperties: function () {
    var properties = this.serviceAreaCountyAllOptionsByRegion().concat(this.serviceAreaCountyAreaDefaultOptionsByRegion());
    _.each(
      this.filterSections,
      function (filterSection) {
        properties = properties.concat(this[filterSection]);
      },
      this
    );
    return properties;
  },

  nextDistance: function (currentDistance) {
    for (var i = 0; this.distanceOptions.length; i++) {
      if (this.distanceOptions[i] == currentDistance) {
        if (i + 1 == this.distanceOptions.length - 1) {
          return null;
        } else {
          return this.distanceOptions[i + 1];
        }
      }
    }
  },

  serviceAreaCountyAllOptionsByRegion: function (region) {
    regionId = region && region.id ? region.id : null;
    switch (regionId) {
      case 'sf':
      case 'la':
        return this.caServiceAreaCountyOptions;
        break;
      case 'detroit':
        return this.detroitServiceAreaCountyOptions;
      case 'swnm':
        return this.swnmServiceAreaCountyOptions;
      case 'nyc':
        return this.nyServiceAreaCountyOptions;
        break;
      default:
        return this.serviceAreaCountyAllOptionsByRegion({ id: 'sf' })
          .concat(this.serviceAreaCountyAllOptionsByRegion({ id: 'nyc' }))
          .concat(this.serviceAreaCountyAllOptionsByRegion({ id: 'swnm' }))
          .concat(this.serviceAreaCountyAllOptionsByRegion({ id: 'detroit' }));
        break;
    }
  },

  serviceAreaCountyAreaDefaultOptionsByRegion: function (region) {
    regionId = region && region.id ? region.id : null;
    switch (regionId) {
      case 'nyc':
        return [
          'service-borough-new-york-city-bronx',
          'service-borough-new-york-city-brooklyn',
          'service-borough-new-york-city-manhattan',
          'service-borough-new-york-city-queens',
          'service-borough-new-york-city-staten-island'
        ];
        break;
      default:
        return this.serviceAreaCountyAreaDefaultOptionsByRegion({ id: 'nyc' });
        break;
    }
  },

  serviceAreaCountyDefaultOptionsByRegion: function (region) {
    switch (region.id) {
      case 'sf':
        return [
          'service-county-california-san-francisco',
          'service-county-california-alameda',
          'service-county-california-santa-clara',
          'service-county-california-san-mateo',
          'service-county-california-contra-costa',
          'service-county-california-marin',
          'service-county-california-napa',
          'service-county-california-sonoma',
          'service-county-california-solano'
        ];
      case 'la':
        return [
          'service-county-california-los-angeles',
          'service-county-california-orange',
          'service-county-california-san-bernardino',
          'service-county-california-ventura',
          'service-county-california-riverside'
        ];
      case 'detroit':
        return ['service-county-michigan-wayne', 'service-county-michigan-oakland', 'service-county-michigan-macomb'];
      case 'swnm':
        return this.swnmServiceAreaCountyOptions;
      case 'nyc':
        return [
          'service-county-new-york-new-york-city',
          'service-county-new-york-albany',
          'service-county-new-york-allegany',
          'service-county-new-york-broome',
          'service-county-new-york-cattaraugus',
          'service-county-new-york-cayuga'
        ];
      default:
        return [];
    }
  },

  datesFromString: function (openFilterQueryString) {
    openFilterQueryVals = openFilterQueryString.split(',');
    var datesFromString = {};
    for (var i = 2; i < openFilterQueryVals.length; i = i + 3) {
      // String holds a date every three elements
      var moment = openFilterQueryVals[i - 2];
      var dayName = openFilterQueryVals[i];
      var hour = openFilterQueryVals[i - 1].slice(0, -2);
      var minutes = openFilterQueryVals[i - 1].slice(-2);
      var datePlaceholder = new Date();
      datePlaceholder.setHours(hour);
      datePlaceholder.setMinutes(minutes);
      datesFromString[moment] = {};
      datesFromString[moment]['dayName'] = dayName;
      datesFromString[moment]['hour'] = hour;
      datesFromString[moment]['minutes'] = minutes;
      datesFromString[moment]['datePlaceholder'] = datePlaceholder;
    }
    return datesFromString;
  },

  normalizeServiceArea: function (areaName) {
    return areaName.replace(/[\s\'\.]+/g, '-').toLowerCase();
  }
};
