import { localStorageService } from '@/utils/local_storage';

const bookmarkHelpers = {
  addToBookmarkCache: (bookmark) => {
    let bookmarks = localStorageService.get('bookmarks') || [];
    bookmarks.push(bookmark);
    localStorageService.set('bookmarks', bookmarks);
  },
  cacheBookmarks: (bookmarks) => {
    localStorageService.set('bookmarks', bookmarks);
  },
  clearBookmarkCache: () => {
    localStorageService.set('bookmarks', null);
  },
  getCachedBookmarks: () => {
    return localStorageService.get('bookmarks');
  },
  needsToBeLoaded: () => {
    let bookmarks = localStorageService.get('bookmarks');
    return bookmarks == undefined || bookmarks == null;
  },
  removeFromBookmarkCache: ({ fetchableType, fetchableId }) => {
    let bookmarks = localStorageService.get('bookmarks');
    let updatedBookmarks = bookmarks.filter((bookmark) => {
      return !(bookmark.fetchable_type == fetchableType && bookmark.fetchable_id.toString() == fetchableId);
    });
    localStorageService.set('bookmarks', updatedBookmarks);
  }
};

export default bookmarkHelpers;
