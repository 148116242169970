import moment from 'moment';
import t from '@/utils/Language/UI-translations';

const calendarHelpers = {
  addLeadingZeroes: (value, totalLength = 2) => {
    return value && parseInt(value) > 0 && String(value).length > 0 ? String(value).padStart(totalLength, '0') : '';
  },
  momentFromParts: (dateParts) => {
    let date = moment(
      dateParts.year + '-' + calendarHelpers.addLeadingZeroes(dateParts.month) + '-' + calendarHelpers.addLeadingZeroes(dateParts.day)
    );
    if (!date.isValid()) {
      return false;
    } else {
      return date;
    }
  },
  dateToParts: (date) => {
    try {
      const dateObject = moment(date).toDate();
      return {
        day: dateObject.getDate().toString(),
        month: (dateObject.getMonth() + 1).toString(), // getMonth returns an int 0-11
        year: dateObject.getFullYear().toString()
      };
    } catch (err) {
      return { day: '', month: '', year: '' };
    }
  },
  validateCalendar: ({ day, month, year }) => {
    const dayValidate = '([1-9]|[12]d|3[01])';
    const monthValidate = '^(0?[1-9]|1[012])$';
    const yearValidate = '/^d{4}$/';

    if (!month.match(monthValidate)) {
      return { field: 'month', error: true, message: t('Please enter a valid month') };
    }

    if (!day.match(dayValidate) || !calendarHelpers.validateDay(+month, +day, +year)) {
      return { field: 'day', error: true, message: t('Please enter a valid date') };
    }

    if (year.length < 4 || +year === 0) {
      return { field: 'year', error: true, message: t('Please enter a valid year') };
    }

    if (new Date(year + '-' + month + '-' + day) > new Date()) {
      return { field: '', error: true, message: t('Date should not be greater than current date') };
    }

    return { field: null, error: false, message: null };
  },
  validateDay: (month, day, year) => {
    // months are intended from 1 to 12
    const months31 = [1, 3, 5, 7, 8, 10, 12]; // months with 31 days
    const months30 = [4, 6, 9, 11]; // months with 30 days
    const months28 = [2]; // the only month with 28 days (29 if year isLeap)

    const isLeap = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

    const valid =
      (months31.indexOf(month) !== -1 && day <= 31) ||
      (months30.indexOf(month) !== -1 && day <= 30) ||
      (months28.indexOf(month) !== -1 && day <= 28) ||
      (months28.indexOf(month) !== -1 && day <= 29 && isLeap);

    return valid;
  }
};

export default calendarHelpers;
